import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    font: 'normal normal 300 18px/27px Inter !important',
    paddingRight: '10px',
    letterSpacing: '-0.31px',
    color: theme.palette.text.primary,
    marginBottom: '10vh',
    border: 'none',
    [theme.breakpoints.down(1300)]: {
      paddingRight: '0px'
    }
  }
}));

export default style;
