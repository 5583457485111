import { Box, AppBar, Toolbar, useMediaQuery } from '@mui/material';
import useStyle from './style';
import Logo from '../../../../components/Logo';
import Language from '../../../../components/Language';

type Props = {
  children?: any;
};

function AppHeader({ children }: Props) {
  const classes = useStyle();

  const isMobile: boolean = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Logo />

          <div className={classes.space} />

          {isMobile && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              mt="22px"
              mr="3px"
            >
              {children}
            </Box>
          )}

          {/*<Language />*/}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppHeader;
