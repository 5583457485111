import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageWrapperInterface } from './interface';
import { setAppState } from '../../../../stories/redux/features/appStateSlice';

const PageWrapper = (props: PageWrapperInterface) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.state) {
      dispatch(setAppState(props.state));
    }
  }, [dispatch, props]);

  return <>{props.children}</>;
};

export default PageWrapper;
