import React from 'react';
import { Box } from '@mui/material';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[] | any;
};

function AppChat({ children }: RootInterface) {
  return (
    <Box
      sx={{
        marginLeft: {
          xl: '78px',
          lg: '60px',
          md: '15px',
          sm: '15px',
          xs: '15px'
        },
        marginRight: {
          xl: '112px',
          lg: '50px',
          md: '15px',
          sm: '15px',
          xs: '15px'
        },
        marginTop: {
          xl: '166px',
          lg: '100px',
          md: '30px',
          sm: '80px',
          xs: '80px'
        },
        display: 'flex',
        padding: {
          xl: '0px 44px 46px 0px',
          lg: '0px 20px 46px 0px',
          md: '0px 0px 15px 0px',
          sm: '0px 0px 15px 0px',
          xs: '0px 0px 15px 0px'
        },
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: {
          xl: '48px',
          lg: '48px',
          md: '32px',
          sm: '24px',
          xs: '24px'
        }
      }}
    >
      {children}
    </Box>
  );
}

export default AppChat;
