import { Box, Typography } from '@mui/material';
import useStyle from './style';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../../utils/contexts/ThemeContext';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

function AppIntroduction({}: any) {
  const classes = useStyle();

  const themeMode = useContext(ThemeContext);

  const baseURL = `/icons/${themeMode.mode === 'dark' ? 'dark' : 'light'}/`;

  const { translate }: TranslateInterface = useLanguage();

  return (
    <div className={classes.root}>
      <Box>
        <Box
          display="flex"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            gap: ' 30px',
            marginBottom: {
              xl: '30px',
              lg: '30px',
              md: '30px',
              sm: '30px',
              xs: '30px'
            }
          }}
        >
          <img
            src={baseURL + 'gpt.svg'}
            alt="Emoji GPT"
            style={{ width: '75px' }}
          ></img>

          <img
            src={baseURL + 'vs.svg'}
            alt="Emoji VS"
            style={{ width: '30px' }}
          ></img>

          <img
            src={baseURL + 'emoji.svg'}
            alt="Emoji Clarice"
            // style={{ width: '96px' }}
          ></img>
        </Box>

        <Typography
          sx={{
            color: '#410A85',
            fontFamily: 'Inter',
            fontSize: {
              xl: '32px',
              lg: '24px',
              md: '24px',
              sm: '24px',
              xs: '24px'
            },
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '125%',
            marginBottom: {
              xl: '4px',
              lg: '4px',
              md: '8px',
              sm: '8px',
              xs: '8px'
            }
          }}
        >
          {translate('page.comparison.introduction.title')}
        </Typography>

        <Typography
          sx={{
            color: '#655679',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%'
          }}
        >
          {translate('page.comparison.introduction.description')}
        </Typography>
      </Box>
    </div>
  );
}

export default AppIntroduction;
