import { useMediaQuery, useTheme, IconButton, Modal, Box } from '@mui/material';
import useStyle from './style';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../utils/contexts/ThemeContext';
import { Close } from '@mui/icons-material';

type Props = {
  open?: any;
  toggle?: any;
  children?: any;
  containerClass?: any;
  hideClose?: any;
};

function ModalRoot({ open, toggle, children, hideClose, ...props }: Props) {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const classes = useStyle();

  return (
    <Modal
      open={open}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(3px)'
      }}
      onClose={toggle}
      {...props}
    >
      <div
        style={{
          height: 'max-content',
          position: 'relative',
          backgroundColor:
            themeMode.mode === 'light'
              ? `${theme.palette.background.paper}`
              : `${theme.palette.background.default}`,
          borderRadius: '13px',
          maxWidth: '590px',
          outline: 'none',
          padding: isMobile ? '10px 20px 10px 20px' : '20px 10px 10px 10px',
          // paddingLeft: isMobile ? '20px' : '40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: isMobile ? '0px 10px 0px 10px' : '0px'
        }}
      >
        {!hideClose && (
          <IconButton
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              bottom: 'auto',
              left: 'auto'
            }}
            size={'small'}
            onClick={toggle}
          >
            <Close fontSize={'small'} />
          </IconButton>
        )}
        <Box
          className={classes.box}
          style={{
            // height: isMobile ? '600px' : '100%',
            maxHeight: isMobile ? '600px' : '100%',
            paddingRight: isMobile ? '0px' : 'px',
            touchAction: 'auto'
          }}
        >
          {children}
        </Box>
      </div>
    </Modal>
  );
}

export default ModalRoot;
