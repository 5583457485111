import useStyle from './style';
import { useContext, useEffect, useState } from 'react';
import { Sidebar } from '../../../index';
import { useAppDispatch } from '../../../../../../../../../utils/hooks/useAppDispatch';
import {
  useMediaQuery,
  Box,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';
import Title from './components/Title';
import Logo from './components/Logo';
import Info from './components/Info';
import Action from './components/Action';

type Props = {
  text?: string;
  summary?: string;
  open: boolean;
  toggle: () => void;
};

function ModalPremium({ open, toggle }: Props) {
  const classes = useStyle();

  const dispatch = useAppDispatch();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  return (
    <Sidebar.Modal.Root
      open={open}
      toggle={() => {
        toggle();
      }}
    >
      <Box
        className={classes.box}
        justifyContent="center"
        style={{
          // height: isMobile ? '600px' : '100%',
          maxHeight: isMobile ? '90vh' : '100%',
          width: isMobile ? '100%' : '444px',
          padding: isMobile ? '16px' : '24px',
          gap: '27px',
          touchAction: 'auto',
          textAlign: 'center'
        }}
      >
        <Logo />

        <Title>Torne-se PRO</Title>

        <Info>
          Acesse recursos exclusivos e análises avançadas. <br />
          Transforme sua experiência com Clarice.ai e maximize sua
          produtividade. <br />
          Junte-se à comunidade PRO hoje!
        </Info>

        <Action
          label="Eu quero!"
          onClick={() => {
            window.open('https://clarice.ai/precos-planos', '_blank');
          }}
        />
      </Box>
    </Sidebar.Modal.Root>
  );
}

export default ModalPremium;
