const getScore = (corrections: any) => {
  const state: any = {
    score: {
      text: 100
    }
  };

  const multiplier: any = {
    deviations: 1,
    spellingAndGrammar: 0.5
  };

  if (
    corrections.filter(correction => correction.type === 'deviations').length >
    10
  ) {
    multiplier.deviations = 0.5;
  }

  if (
    corrections.filter(correction => correction.type === 'spellingAndGrammar')
      .length > 10
  ) {
    multiplier.spellingAndGrammar = 0.2;
  }

  if (corrections.length === 0) {
    return state;
  }

  corrections.map(correction => {
    if (correction.type === 'deviations') {
      state.score.text -= multiplier.deviations;
    }

    if (correction.type === 'spellingAndGrammar') {
      state.score.text -= multiplier.spellingAndGrammar;
    }

    return correction;
  });

  state.score.text = state.score.text > 0 ? Math.floor(state.score.text) : 0;

  return state;
};

export default getScore;
