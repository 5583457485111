import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  container: {
    marginTop: '5px',
    marginBottom: '5px',
    padding: '9px',
    borderRadius: '3px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:
        theme.palette.mode === 'light' ? 'rgba(232, 232, 232, 0.5)' : '#3f3f3f'
    }
  },
  active: {
    cursor: 'unset!important'
  },
  name: {},
  number: {},
  tooltip: {
    backgroundColor: `${theme.palette.common.tooltip}!important`,
    display: 'flex',
    padding: '12px 5px!important',
    justifyContent: 'center',
    alignItems: 'center',
    // gap: '8px',
    flex: '1 0 0',
    borderRadius: '8px'
  },
  arrow: {
    color: theme.palette.common.tooltip + ' !important'
  },
  info: {
    textAlign: 'center',
    font: 'normal normal 400 14px Inter !important',
    lineHeight: '140%!important'
  },
  tooltipCorrection: {
    marginRight: '86px!important'
  },
  tooltipClarity: {
    marginRight: '77px!important'
  },
  tooltipConciseness: {
    marginRight: '87px!important'
  },
  tooltipStrength: {
    marginRight: '65px!important'
  },
  tooltipOriginality: {
    marginRight: '109px!important'
  },
  tooltipPremium: {
    marginRight: '58px!important'
  }
}));

export default style;
