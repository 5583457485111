import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../../../../../utils/contexts/ThemeContext';
import useStyles from './style';

const Title = ({ children }: ActionInterface) => {
  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box style={{ width: '100%' }}>
      <Typography
        style={{
          fontFamily: 'Nunito',
          fontSize: isMobile ? '22px' : '30px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
          color:
            themeMode.mode === 'dark' ? theme.palette.text.primary : '#39274B'
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default Title;
