import React, { useState } from 'react';
import useStyle from './style';
import { Box, Collapse } from '@mui/material';
import { Editor } from '../../../index';

type Props = {
  text: string;
};

function Root({ text }: Props) {
  const classes = useStyle();

  const [openCollapse, setOpenCollapse] = useState(false);

  const score = {
    characters: text.replace(/\n+/g, '').length,
    words: text.split(/\s+/).filter(el => el.length > 0 && el !== '—').length,
    sentences: text.split(/[!?.]\s/).filter(el => el.length > 0).length,
    paragraphs: text.split(/\n+/).filter(el => el.length > 0).length
  };

  const updateStatusCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <Box
      style={{
        position: 'fixed',
        bottom: '5px',
        right: '370px',
        zIndex: 1001
      }}
      display={'flex'}
      flexDirection={'column'}
      p={'19px'}
      pb={'0px'}
    >
      <Box mt={'12px'} mb={'8px'}>
        <Box className={classes.root}>
          <Editor.Metrics.Item
            title="Palavras"
            type="word"
            total={score.words}
            open={openCollapse}
            updateStatusCollapse={updateStatusCollapse}
            last={!openCollapse}
          />

          <Collapse in={openCollapse}>
            <Editor.Metrics.Item
              title="Caracteres"
              type="character"
              total={score.characters}
            />
            <Editor.Metrics.Item
              title="Frases"
              type="sentence"
              total={score.sentences}
            />
            <Editor.Metrics.Item
              title="Parágrafos"
              type="paragraph"
              total={score.paragraphs}
              last
            />
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

export default Root;
