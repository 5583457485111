import React from 'react';
import { Box } from '@mui/material';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[];
};

function AppSteps({ children }: RootInterface) {
  return (
    <Box
      sx={{
        marginLeft: {
          xl: '112px',
          lg: '112px',
          md: '112px',
          sm: '25px',
          xs: '25px'
        },
        marginTop: {
          xl: '156px',
          lg: '100px',
          md: '106px',
          sm: '20px',
          xs: '20px'
        },
        display: {
          xl: 'flex',
          lg: 'flex',
          md: 'flex',
          sm: 'none',
          xs: 'none'
        },
        padding: {
          xl: '70px 44px 70px 0px',
          lg: '50px 44px 50px 0px',
          md: '70px 25px 30px 0px',
          sm: '70px 25px 30px 0px',
          xs: '70px 25px 30px 0px'
        },
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: {
          xl: '48px',
          lg: '48px',
          md: '48px',
          sm: '48px',
          xs: '48px'
        },
        borderRight: {
          xl: '1px solid #A4A3FF',
          lg: '1px solid #A4A3FF',
          md: '1px solid #A4A3FF',
          sm: '0px solid #A4A3FF',
          xs: '0px solid #A4A3FF'
        }
      }}
    >
      {children}
    </Box>
  );
}

export default AppSteps;
