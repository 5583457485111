import React, { useContext } from 'react';
import useStyle from './style';
import { Box, useTheme } from '@mui/material';

type RootProps = {
  loading?: boolean;
  children: React.ReactNode;
};

function Root({ children }: RootProps) {
  const classes = useStyle();

  const theme = useTheme();

  return (
    <Box flexGrow={1} display={'flex'} justifyContent={'center'} width={'100%'}>
      <Box
        sx={{
          marginLeft: '40px',
          [theme.breakpoints.down(1300)]: {
            marginLeft: '0px'
          },
          [theme.breakpoints.down(600)]: {
            marginLeft: '20px'
          }
        }}
        maxWidth={'830px'}
        width={'100%'}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Root;
