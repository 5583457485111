import React from 'react';
import { Box } from '@mui/material';
import useStyle from './style';

type Props = {
  children: React.ReactNode;
  innerHeight?: number;
};

function ScoreBody({ children, innerHeight }: Props) {
  const classes = useStyle();

  return (
    <Box
      pt={innerHeight && innerHeight < 750 ? '5px' : '17px'}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
    >
      {children}
    </Box>
  );
}

export default ScoreBody;
