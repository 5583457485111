import React from 'react';
import { Box } from '@mui/material';

type Interface = {
  children: React.ReactElement | React.ReactElement[];
};

function AppPrompts({ children }: Interface) {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        paddingTop: '0px',
        paddingBottom: '20px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '12px'
      }}
    >
      {children}
    </Box>
  );
}

export default AppPrompts;
