/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../utils/models/dispatchTypes';
import { decreaseLoading, increaseLoading } from './loading';
import RequestErrorHelper from '../../utils/helpers/RequestErrorHelper';
import ReviewerRequest from '../requests/reviewer';
import notify from '../../utils/notify';

export const checkText =
  (props?: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse = await ReviewerRequest.checkText(
        props
      );

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const saveText =
  (props?: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse = await ReviewerRequest.saveText(
        props
      );

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const saveTitle =
  (props?: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse = await ReviewerRequest.saveTitle(
        props
      );

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getText =
  (id?: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse = await ReviewerRequest.getText(id);

      if (cb && cb.success) {
        cb.success(payload);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const acceptSuggestion =
  (correction?: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse =
        await ReviewerRequest.acceptSuggestion(correction);

      if (cb && cb.success) {
        cb.success();
      }

      notify.success('Obrigada pelo feedback!');
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const dismissSuggestion =
  (correction?: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse =
        await ReviewerRequest.dismissSuggestion(correction);

      if (cb && cb.success) {
        cb.success();
      }

      notify.success('Obrigada pelo feedback!');
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getSummary =
  (text?: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      const payload: models.ChatResponse = await ReviewerRequest.getSummary(
        text
      );

      if (cb && cb.success) {
        cb.success(payload.summary);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };
