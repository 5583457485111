import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  customIcon: {
    color: '#949494',
    fill: 'currentColor',
    pointerEvents: 'none',
    verticalAlign: 'middle',
    width: '0.5em',
    height: '0.5em',
    fontFamily: "'Inter'!important",
    fontSize: '1.3rem!important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px!important'
    }
  },
  customIconHeader: {
    color: '#949494',
    fill: 'currentColor',
    pointerEvents: 'none',
    verticalAlign: 'middle',
    width: '0.5em',
    height: '0.5em',
    fontFamily: "'Inter'!important",
    fontSize: '1.0rem!important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px!important'
    }
  }
}));

export default style;
