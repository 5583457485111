import { RouteType } from './interface/config';
import ChoicePage from '../../../../layouts/App/pages/choice';
import RewriterPage from '../../../../layouts/App/pages/reWriter';
import HashtagGeneratorPage from '../../../../layouts/App/pages/hashtagGenerator';
import ComparisonPage from '../../../../layouts/App/pages/comparison';
import ReviewerPage from '../../../../layouts/App/pages/reviewer';
import { EditorContextProvider } from '../../../contexts/EditorContext';
import SummarizePage from '../../../../layouts/App/pages/summarize';

const routes: RouteType[] = [
  {
    path: '/',
    element: <ChoicePage />,
    state: 'choice',
    protected: false
  },
  {
    path: '/comparison',
    element: <ComparisonPage />,
    state: 'comparison',
    protected: false,
    child: [
      {
        path: ':code',
        element: <ComparisonPage />,
        state: 'comparison',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/reviewer',
    element: (
      <EditorContextProvider>
        <ReviewerPage />
      </EditorContextProvider>
    ),
    state: 'reviewer',
    protected: false,
    child: [
      {
        path: ':code',
        element: (
          <EditorContextProvider>
            <ReviewerPage />
          </EditorContextProvider>
        ),
        state: 'reviewer',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/re-writer',
    element: <RewriterPage />,
    state: 're-writer',
    protected: false,
    child: [
      {
        path: ':code',
        element: <RewriterPage />,
        state: 're-writer',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/summarize-text',
    element: <SummarizePage />,
    state: 'summarize-text',
    protected: false,
    child: [
      {
        path: ':code',
        element: <SummarizePage />,
        state: 'summarize-text',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/hashtag-generator',
    element: <HashtagGeneratorPage />,
    state: 'hashtag-generator',
    protected: false,
    child: [
      {
        path: ':code',
        element: <HashtagGeneratorPage />,
        state: 'hashtag-generator',
        protected: false,
        sidebar: 0
      }
    ]
  }
];

export default routes;
