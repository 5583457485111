import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    border:
      theme.palette.mode === 'light'
        ? `1px solid ${theme.palette.background.darkGrey}`
        : `1px solid #404040`,
    borderRadius: '6px !important',
    '&:before': {
      display: 'none'
    },
    margin: '0 !important',
    marginBottom: '18px !important',
    boxShadow: '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
    // maxWidth: '400px'
  }
}));

export default style;
