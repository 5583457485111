import React from 'react';
import { Box, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

type Interface = {
  title: string;
  description: string;
  active?: boolean;
};

function AppStep({ title, description, active = false }: Interface) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: ' 24px'
      }}
    >
      <Box
        sx={{
          padding: '12px',
          width: '48px',
          height: '48px',
          flexShrink: 0,
          borderRadius: '8px',
          backgroundColor: active ? 'rgba(164, 163, 255, 0.15)' : '#FFFFFF',
          border: active
            ? '0px solid rgba(0, 0, 0, 0.15)'
            : '1px solid rgba(0, 0, 0, 0.15)',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <TaskAltIcon sx={{ color: '#7C24FB', opacity: active ? 1 : 0.55 }} />
      </Box>

      <Box>
        <Typography
          sx={{
            color: active ? '#4F3D66' : '#39274B',
            opacity: active ? 1 : 0.55,
            fontFamily: 'Inter',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '125%',
            marginBottom: '4px'
          }}
        >
          {title}
        </Typography>

        <Typography
          sx={{
            color: active ? '#4F3D66' : '#39274B',
            opacity: active ? 1 : 0.55,
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%'
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

export default AppStep;
