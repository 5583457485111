import React from 'react';
import useStyle from './style';
import { InputBase, useMediaQuery, useTheme } from '@mui/material';

type Props = {
  id: string;
  title: string;
  onSetTitle: (title: string) => void;
  onSaveTitle: any;
};

function Title({ id, title, onSetTitle, onSaveTitle }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

  const handleBlur = () => {
    onSaveTitle({ id, title });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSetTitle(e.target.value);
  };

  return (
    <InputBase
      className={classes.root}
      value={title}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
        handleChange(e)
      }
      onBlur={handleBlur}
      sx={{
        padding: '12px',
        fontSize: isMobile ? '22px' : '28px',
        fontFamily: 'Inter'
      }}
      spellCheck={false}
      placeholder="Adicione um título..."
      fullWidth
    />
  );
}

export default Title;
