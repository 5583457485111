import React, { ReactNode } from 'react';
import { RouterInterface } from './interface';
import AppLayout from '../../layouts/App';
import generateRoute from './function/generateRoute';
import { Route, Routes, Navigate } from 'react-router-dom';
import listRoutes from './function/generateRoute/routes';

const Router = (props: RouterInterface) => {
  const routes: ReactNode = generateRoute(listRoutes);

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        {routes}
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
