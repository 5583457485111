const getCorrections = (data: any, category, subscriber = false) => {
  if (
    !data ||
    !data.corrections ||
    !data.corrections.deviations ||
    !data.corrections.spellingAndGrammar
  )
    return { corrections: [] };

  const corrections: any = [];

  const getCategory = (score: any) => {
    if (score && score?.correcao > 0) return 'correction';
    if (score && score?.clareza > 0) return 'clarity';
    if (score && score?.concisao > 0) return 'conciseness';
    if (score && score?.forca > 0) return 'strength';
    if (score && score?.originalidade > 0) return 'originality';
    if (score && score?.premium > 0) return 'premium';

    return 'correction';
  };

  data?.corrections?.deviations.map((item: any) => {
    corrections.push({
      _id: item._id,
      id: item.id,
      type: 'deviations',
      category: getCategory(item.score),
      custom: item.custom,
      spans: item.spans,
      par: item.par,
      replacement: item.replacement == '' ? [''] : item.replacement || []
    });
  });

  data?.corrections?.spellingAndGrammar.map((item: any) => {
    console.log('ITEM: ', item);

    corrections.push({
      _id: item._id,
      id: item.id || item.rule.id,
      type: 'spellingAndGrammar',
      category: 'correction',
      custom: item?.custom
        ? item.custom
        : {
            title: item.shortMessage || item.message,
            feedback: item.message
          },
      spans: item.spans,
      par: item.par,
      replacement: item.suggestions || []
    });
  });

  if (!subscriber) {
    return {
      corrections,
      premium: corrections.filter(item => item.category === 'premium')
    };
  }

  return {
    corrections,
    premium: []
  };
};

export default getCorrections;
