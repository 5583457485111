import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    paddingLeft: '0px!important',
    fontSize: '32px',
    lineHeight: '32px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    letterSpacing: 0,
    maxWidth: 'calc(100% - 10px)',
    color: theme.palette.text.primary,
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      lineHeight: '25px'
      // paddingLeft: '30px!important'
    },
    '& .MuiInputBase-input': {
      whiteSpace: 'nowrap',
      overflow: 'hidden!important',
      textOverflow: 'ellipsis!important'
    }
  }
}));

export default style;
