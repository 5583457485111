import Root from './components/Root';
import Title from './components/Title';
import Text from './components/Text';
import Submit from './components/Button/Submit';
import Copy from './components/Button/Copy';
import MetricsRoot from './components/Metrics/Root';
import MetricsItem from './components/Metrics/Item';
import ToolbarIcon from './components/Toolbar /Icon';

export const Editor = {
  Root,
  Title,
  Text,
  Button: {
    Submit,
    Copy
  },
  Metrics: {
    Root: MetricsRoot,
    Item: MetricsItem
  },
  Toolbar: {
    Icon: ToolbarIcon
  }
};
