import React, { useContext } from 'react';
import { Box } from '@mui/material';
import useStyle from './style';
import Button from '@mui/material/Button';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';

type Props = {
  modal: {
    score: {
      open?: boolean;
      toggle: () => void;
    };
    summary: {
      open?: boolean;
      toggle: () => void;
    };
    premium: {
      open?: boolean;
      toggle: () => void;
    };
  };
};

function ScoreAction({ modal }: Props) {
  const classes = useStyle();

  const themeMode = useContext(ThemeContext);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          // modal.score.toggle()
          modal.premium.toggle();
        }}
        style={{
          width: '100%',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '160%',
          backgroundColor: themeMode.mode === 'light' ? '#410A85' : '#09B286',
          color: 'white',
          letterSpacing: '0.1px',
          cursor: 'pointer',
          textTransform: 'unset',
          marginTop: '12px',
          borderRadius: '8px',
          padding: '8px 40px',
          gap: '10px'
        }}
      >
        Ver detalhes
      </Button>

      <Button
        variant="outlined"
        onClick={() => {
          // modal.summary.toggle()
          modal.premium.toggle();
        }}
        sx={{
          width: '100%',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '160%',
          borderColor: themeMode.mode === 'light' ? '#410A85' : '#09B286',
          color: themeMode.mode === 'light' ? '#410A85' : '#09B286',
          letterSpacing: '0.1px',
          cursor: 'pointer',
          textTransform: 'unset',
          marginTop: '12px',
          borderRadius: '8px',
          padding: '6px 40px',
          gap: '10px',
          '&:hover': {
            backgroundColor: themeMode.mode === 'light' ? '#410A85' : '#09B286',
            color: 'white'
          }
        }}
      >
        Sumarização
      </Button>
    </>
  );
}

export default ScoreAction;
