import notify from '../../notify';

const RequestErrorHelper = async (e: any) => {
  if (process.env.NODE_ENV !== 'production') {
    // console.log(e);
  }

  if (e.response) {
    if (
      e.response.status === 401 &&
      e.response.data.message === 'Unauthorized'
    ) {
      return notify.error(
        'Sua sessão expirou! Acesse novamente para continuar.'
      );
    }
    if (e.response.status >= 400 && e.response.status < 500) {
      // console.log(e.response);

      return notify.error(e.response.data.message);
    }
  }

  return notify.error('Ops! Algo deu errado. Tente novamente.');
};

export default RequestErrorHelper;
