import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  container: {
    // paddingTop: '4.2rem',
    // paddingBottom: "1.5rem",
    // minHeight: '80px'
  },
  input: {
    width: '100%',
    display: 'flex',
    padding: '18px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '10px',
    borderRadius: '60px',
    border: '1px solid #E2E8F0',
    background: '#FFF',
    color: '#4F3D66',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%'
  }
}));

export default style;
