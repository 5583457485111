import React from 'react';
import { Box } from '@mui/material';
import useStyle from './style';

type Props = {
  children: React.ReactNode;
  innerHeight?: number;
};

function ScoreRoot({ children }: Props) {
  const classes = useStyle();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      p={innerHeight < 750 ? '8px 6px' : '19px 15px'}
      className={classes.root}
    >
      {children}
    </Box>
  );
}

export default ScoreRoot;
