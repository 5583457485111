const data = {
  corrections: {
    deviations: [
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 239,
            length: 12,
            content: 'Infelizmente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a01de25600cdbef736',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'USO_CERCA_DE',
        score: {
          clareza: 2
        },
        replacement: [''],
        replace: true,
        spans: [
          {
            offset: 285,
            length: 8,
            content: 'cerca de'
          }
        ],
        _id: '663327a01de256514bbef737',
        custom: {
          title: 'Evite usar <i>cerca de</i>',
          feedback:
            'Prefira números exatos e utilize <i>cerca de</i> apenas quando se referir a quantidades grandes demais para serem contadas.',
          more: '<ul> <li> <p> Quanto mais precisa a informação, melhor. Considere remover do texto. </p> <p> <span style="color: red; font-weight: bold; text-decoration-line: line-through;">Cerca de</span> dez pessoas conversaram comigo ontem. <br /> </p> </li> <li> <p> Quando for indispensável, use apenas para números redondos que não podem ser contados com facilidade. </p> <p> <span style="font-weight: bold; text-decoration-line: underline;">Cerca de</span> 300 pessoas estavam na festa. <br /> </p> </li> </ul>'
        }
      },
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 408,
            length: 9,
            content: 'altamente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a01de25660cbbef738',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'USO_PALAVRAS_REPETIDAS',
        spans: [
          {
            offset: 642,
            length: 5,
            content: 'plano',
            par: 0
          },
          {
            offset: 712,
            length: 5,
            content: 'plano',
            par: 0
          },
          {
            offset: 904,
            length: 6,
            content: 'planos',
            par: 0
          }
        ],
        par: 0,
        score: {
          premium: 1
        },
        _id: '663327a01de256cf6cbef739',
        custom: {
          title: 'Palavras repetidas: <i>plano</i>',
          feedback: 'Palavras repetidas deixam a leitura cansativa.',
          more: "<ul> <li> <p> Procure usar sinônimos ou reescrever a frase para evitar o uso de palavras repetidas. </p> <p> <span style='color: red'> Profissionais devem buscar o equilíbrio na <span style='text-decoration-Line: underline'>vida profissional</span>. </span> </p> <p> <span style='color: green'> Profissionais devem buscar o equilíbrio na <span style='text-decoration-Line: underline'>carreira</span>. </span> </p> </li> </ul>"
        }
      },
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 693,
            length: 12,
            content: 'Infelizmente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a01de2568ecbbef73a',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 767,
            length: 13,
            content: 'Especialmente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a01de256176abef73b',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'USO_FRASE_CLICHE',
        score: {
          premium: 1
        },
        spans: [
          {
            offset: 945,
            length: 18,
            content: 'Antes de mais nada'
          }
        ],
        _id: '663327a01de256e2c3bef73c',
        custom: {
          title: 'Evite usar clichês',
          feedback:
            'Clichês são expressões que estão gastas pelo uso, que perderam o valor expressivo.',
          more: '<ul> <li> <p> Os clichês, chavões, lugares-comuns e frases feitas tiram a originalidade e a força do seu texto. Evite a todo custo usar clichês. </p> </li> <li> <p>Se você acha que aqui cabe uma metáfora, crie uma.</p> </li> </ul>'
        }
      },
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 1496,
            length: 11,
            content: 'abertamente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a11de256c325bef73e',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'USO_CERTEZA_QUE',
        score: {
          premium: 1
        },
        replacement: ['certeza de que'],
        replace: true,
        spans: [
          {
            offset: 1672,
            length: 11,
            content: 'certeza que'
          }
        ],
        _id: '663327a11de2562d5fbef73f',
        custom: {
          title: 'Uso de <i>certeza que</i>',
          feedback:
            'Prefira usar a forma <i>certeza de que</i>, com a preposição <i>de</i>.',
          more: "<ul><li>A forma <i>certeza que</i> não é recomendada; prefira usar a forma <i>certeza de que</i>, com a preposição <i>de.</i></li></ul><p>Exemplo:</p><p><span style='color: #ff0000;'>Ele tinha certeza que fez a escolha certa.</span></p><p><span style='color: #008000;'>Ele tinha certeza de que fez a escolha certa.</span></p><ul><li>Outras palavras que acompanham a preposição <i>de</i> também seguem a mesma recomendação, como <i>medo de</i> ou <i>esperança de.</i></li></ul>"
        }
      },
      {
        id: 'USO_PALAVRAS_REPETIDAS',
        spans: [
          {
            offset: 1851,
            length: 7,
            content: 'difícil',
            par: 0
          },
          {
            offset: 1871,
            length: 7,
            content: 'difícil',
            par: 0
          },
          {
            offset: 1986,
            length: 8,
            content: 'difíceis',
            par: 0
          }
        ],
        par: 0,
        score: {
          premium: 1
        },
        _id: '663327a11de2567229bef740',
        custom: {
          title: 'Palavras repetidas: <i>difícil</i>',
          feedback: 'Palavras repetidas deixam a leitura cansativa.',
          more: "<ul> <li> <p> Procure usar sinônimos ou reescrever a frase para evitar o uso de palavras repetidas. </p> <p> <span style='color: red'> Profissionais devem buscar o equilíbrio na <span style='text-decoration-Line: underline'>vida profissional</span>. </span> </p> <p> <span style='color: green'> Profissionais devem buscar o equilíbrio na <span style='text-decoration-Line: underline'>carreira</span>. </span> </p> </li> </ul>"
        }
      },
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 1907,
            length: 11,
            content: 'remotamente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a11de256d7b3bef741',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 1944,
            length: 12,
            content: 'Pessoalmente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a11de2569bc7bef742',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'ADVERBIOS_MENTE',
        spans: [
          {
            offset: 2140,
            length: 12,
            content: 'pessoalmente',
            par: 0
          }
        ],
        par: 0,
        score: {
          forca: 3
        },
        _id: '663327a11de2569f8cbef743',
        custom: {
          title: 'Advérbio terminado em <b><i>mente</i></b>',
          feedback:
            'A repetição de dois ou mais advérbios terminados em <i>-mente</i> dentro do mesmo parágrafo torna o seu texto pesado.',
          more: "<ul> <li> <p>A maioria dos advérbios é dispensável.</p> <p> O copo está <span style='color: red; font-weight: bold; text-decoration-Line: line-through'>completamente</span> cheio. <br /> </p> </li> <li> <p>Caso necessário, reescreva utilizando substantivos e verbos.</p> <p> <span style='color: green'>O copo está com água até a borda.</span> <br /> </p> </li> </ul>"
        }
      },
      {
        id: 'PARAGRAFO_LONGO',
        spans: [
          {
            offset: 1468,
            length: 859,
            content: ''
          }
        ],
        par: 0,
        score: {
          premium: 1
        },
        _id: '663327a11de256f12cbef744',
        custom: {
          title: 'Parágrafo longo',
          feedback:
            'Um parágrafo bem escrito deve conter apenas uma ideia ou raciocínio completo. Muitos assuntos em um mesmo parágrafo fazem o leitor se perder.'
        }
      }
    ],
    spellingAndGrammar: [
      {
        type: 'gramatica',
        _id: '663327a11de25610c4bef74b',
        message: 'Possível erro de concordância de género.',
        shortMessage: '',
        rule: {
          id: 'A_WORD',
          subId: '1',
          sourceFile: 'grammar.xml',
          description: 'Concordância de género e número: A + palavra',
          issueType: 'grammar',
          urls: [
            {
              value: 'https://pt.wikipedia.org/wiki/Artigo_(gram%C3%A1tica)'
            }
          ],
          category: {
            id: 'GRAMMAR',
            name: 'Gramática Geral'
          }
        },
        spans: [
          {
            offset: 252,
            length: 6,
            content: 'a Rock'
          }
        ],
        suggestions: ['o Rock']
      },
      {
        type: 'gramatica',
        _id: '663327a01de2568a94bef73d',
        message:
          '‘Onde’ pode ser subsituido ‘em que lugar’. Pretende dizer: “aonde vamos” ou “donde vamos”?',
        shortMessage: '',
        rule: {
          id: 'AONDE_ONDE',
          subId: '3',
          sourceFile: 'grammar.xml',
          description: 'Confusão: aonde - onde',
          issueType: 'uncategorized',
          urls: [
            {
              value:
                'https://ciberduvidas.iscte-iul.pt/consultorio/perguntas/onde-e-aonde/548'
            }
          ],
          category: {
            id: 'CONFUSED_WORDS',
            name: 'Confusão de Palavras'
          }
        },
        spans: [
          {
            offset: 918,
            length: 10,
            content: 'onde vamos'
          }
        ],
        suggestions: ['aonde vamos', 'donde vamos']
      },
      {
        type: 'pontuacao',
        _id: '663327a11de2561a8abef752',
        message:
          'Esta conjunção deve ser separada por vírgulas, e só deve ser utilizada no ínicio duma frase para efeitos de estilo.',
        shortMessage: 'Separe com vírgulas',
        rule: {
          id: 'VERB_COMMA_CONJUNCTION',
          subId: '22',
          sourceFile: 'grammar.xml',
          description:
            'Locuções entre vírgulas: portanto, por exemplo, na verdade',
          issueType: 'uncategorized',
          urls: [
            {
              value:
                'https://ciberduvidas.iscte-iul.pt/consultorio/perguntas/pontuacao-virgula-antes-de-mas/7047'
            }
          ],
          category: {
            id: 'PUNCTUATION',
            name: 'Pontuação'
          }
        },
        spans: [
          {
            offset: 1871,
            length: 12,
            content: 'difícil pois'
          }
        ],
        suggestions: ['difícil, pois']
      }
    ],
    doubts: []
  },
  categories: [
    {
      name: 'Correção',
      type: 'correction',
      tooltip: 'Melhora a ortografia, gramática e pontuação.',
      number: 0
    },
    {
      name: 'Clareza',
      type: 'clarity',
      tooltip: 'Ajuda a tornar a sua escrita mais fácil de entender.',
      number: 0
    },
    {
      name: 'Concisão',
      type: 'conciseness',
      tooltip: 'Transmite a mensagem de forma direta e clara.',
      number: 0
    },
    {
      name: 'Força',
      type: 'strength',
      tooltip: 'Potencializa o impacto e a persuasão do seu texto.',
      number: 0
    },
    {
      name: 'Originalidade',
      type: 'originality',
      tooltip:
        'Auxilia na criação de um texto único e autêntico, oferecendo ideias frescas e criativas.',
      number: 0
    },
    {
      name: 'PRO',
      type: 'premium',
      tooltip:
        'Eleva sua escrita a um nível superior com uma correção impecável, clareza excepcional e resultados impactantes.',
      number: 0
    }
  ]
};

export default data;
