import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  logo: {
    cursor: 'pointer',
    width: '110px'
  }
}));

export default style;
