import React, { useState } from 'react';
import useStyle from './style';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import TooltipRoot from '@mui/material/Tooltip';

type Props = {
  open?: boolean;
  title?: string | Element | React.ReactElement | React.ReactElement[] | any;
  placement?: any;
  small?: boolean;
  children: React.ReactElement | React.ReactElement[] | any;
};

function Tooltip({
  open = false,
  title,
  placement = 'bottom',
  small = false,
  children
}: Props) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const classes = useStyle();

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClose = () => {
    setOpenTooltip(false);
  };

  const handleOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <TooltipRoot
      classes={{
        tooltip: small ? classes.small : classes.tooltip,
        arrow: classes.arrow
      }}
      open={title !== '' ? open || openTooltip : false}
      title={<Typography className={classes.info}>{title}</Typography>}
      placement={placement}
      arrow
      onClose={handleClose}
      onOpen={handleOpen}
    >
      {children}
    </TooltipRoot>
  );
}

export default Tooltip;
