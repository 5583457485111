import React from 'react';
import { Box } from '@mui/material';
import useStyle from './style';

type Props = {
  innerHeight?: number;
};

function ScoreHeader({ innerHeight }: Props) {
  const classes = useStyle();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      mt={innerHeight && innerHeight < 750 ? '3px' : '7px'}
    >
      <h2 className={classes.scoreText}>Score do texto</h2>
    </Box>
  );
}

export default ScoreHeader;
