import React, { useContext } from 'react';
import useStyle from './style';
import {
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';
import Tooltip from '../../../../../../../components/Tooltip';
import { ArrowForwardRounded } from '@mui/icons-material';
import getWordLimit from '../../../../../../../../../utils/functions/getWordLimit';

type Props = {
  id: string;
  title: string;
  text: string;
  editorState: any;
  activeCategory: string;
  activeItem: any;
  onProcess: any;
  loading: boolean;
};

function Submit({
  id,
  title,
  text,
  editorState,
  activeCategory,
  activeItem,
  onProcess,
  loading
}: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

  const { limit, counter, tooltip } = getWordLimit(text);

  return (
    <Box
      sx={{
        display: 'flex',
        marginBottom: '30px',
        textAlign: 'right',
        justifyContent: 'right'
      }}
    >
      <Tooltip
        open={counter > limit && tooltip !== ''}
        title={counter > limit && tooltip !== '' ? tooltip : 'Processar texto'}
        small={true}
      >
        <span>
          <Button
            variant={'contained'}
            style={{
              color:
                themeMode.mode === 'light'
                  ? `${theme.palette.text.white}`
                  : `${theme.palette.text.white}`,
              background:
                themeMode.mode === 'light'
                  ? `${theme.palette.primary.main}`
                  : `${theme.palette.secondary.dark}`,
              width: isMobile ? '10rem' : '10rem',
              padding: '0 1.5rem',
              borderRadius: '30px'
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              textTransform: 'none!important',
              fontFamily: 'Inter'
            }}
            disableElevation
            className={classes.button}
            onClick={() =>
              onProcess({
                id,
                title,
                text,
                editorState,
                activeCategory,
                activeItem
              })
            }
          >
            {loading ? (
              <>
                Carregando{' '}
                <CircularProgress
                  size={20}
                  style={{
                    color:
                      themeMode.mode === 'light'
                        ? `${theme.palette.secondary.main}`
                        : `${theme.palette.text.white}`,
                    marginLeft: '10px'
                  }}
                />
              </>
            ) : (
              <>
                Vamos lá{' '}
                <ArrowForwardRounded
                  style={{
                    color:
                      themeMode.mode === 'light'
                        ? `${theme.palette.secondary.main}`
                        : `${theme.palette.text.white}`
                  }}
                />
              </>
            )}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
}

export default Submit;
