import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  small: {
    backgroundColor: `${theme.palette.common.tooltip}!important`
  },
  tooltip: {
    backgroundColor: `${theme.palette.common.tooltip}!important`,
    paddingTop: '12px!important',
    paddingBottom: '12px!important'
  },
  arrow: {
    color: theme.palette.common.tooltip + ' !important'
  },
  info: {
    textAlign: 'center',
    font: 'normal normal 400 12px/14px Inter !important',
    lineHeight: '140%!important'
  }
}));

export default style;
