import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '150px',
    paddingLeft: '25px',
    paddingRight: '20px',
    flex: '0 0 150px',
    height: '48px',
    textTransform: 'none',
    marginLeft: '19px',
    borderRadius: '30px',
    '&:disabled': {
      background: theme.palette.primary.main,
      opacity: 0.7,
      color: 'white'
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      flex: 'auto'
    }
  }
}));

export default style;
