import React from 'react';
import { Box, Typography } from '@mui/material';

type Interface = {
  prompt: string;
  handlePrompt: (prompt: string) => void;
};

function AppPrompt({ prompt, handlePrompt }: Interface) {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'flex',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: ' 10px',
        borderRadius: '12px',
        background: '#FFF',
        boxShadow: '0px 16px 40px -8px rgba(33, 37, 41, 0.08)',
        border: '1px solid #FFFFFF',
        '&:hover': {
          border: '1px solid rgba(124, 36, 251, 0.80)',
          boxShadow: '0px 16px 40px -8px rgba(33, 37, 41, 0.08)'
        }
      }}
      onClick={() => handlePrompt(prompt)}
    >
      <Box>
        <Typography
          sx={{
            color: '#655679',
            opacity: 1,
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '125%'
          }}
        >
          {prompt}
        </Typography>
      </Box>
    </Box>
  );
}

export default AppPrompt;
