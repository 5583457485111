import useStyle from './style';
import { useState } from 'react';
import { Sidebar } from '../../../index';

type Props = {
  open: boolean;
  toggle: () => void;
};

function ModalScore({ open, toggle }: Props) {
  const classes = useStyle();

  const [loading, setLoading] = useState(true);

  return (
    <Sidebar.Modal.Root
      open={open}
      toggle={() => {
        toggle();
      }}
    >
      <p>TESTE</p>
    </Sidebar.Modal.Root>
  );
}

export default ModalScore;
