import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/contexts/ThemeContext';
import ConfettiExplosion from 'react-confetti-explosion';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

type Interface = {
  outputs: any;
  chosen?: any;
};

function AppChosenSuccess({ outputs, chosen }: Interface) {
  const themeMode = useContext(ThemeContext);

  const baseURL = `/icons/${themeMode.mode === 'dark' ? 'dark' : 'light'}/`;

  const { translate }: TranslateInterface = useLanguage();

  const getInfo = () => {
    const response = outputs.find((output: any) => output.id === chosen);

    if (!response) {
      return {
        type: '',
        title: '',
        description: '',
        image: {
          src: '',
          alt: ''
        }
      };
    }

    if (response.by === 'ChatGPT') {
      return {
        type: 'ChatGPT',
        title: translate('page.comparison.chosen.ChatGPT.title'),
        description: translate('page.comparison.chosen.ChatGPT.description'),
        image: {
          src: baseURL + 'gpt.svg',
          alt: 'Emoji ChatGPT'
        }
      };
    }

    return {
      type: 'Clarice.AI',
      title: translate('page.comparison.chosen.Clarice.AI.title'),
      description: translate('page.comparison.chosen.Clarice.AI.description'),
      image: {
        src: baseURL + 'emoji.svg',
        alt: 'Emoji Clarice'
      }
    };
  };

  const { type, title, description, image }: any = getInfo();

  if (type === '') {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: ' 22px',
        marginBottom: {
          xl: '-25px',
          lg: '-25px',
          md: '-5px',
          sm: '-5px',
          xs: '-5px'
        },
        paddingLeft: {
          xl: '0px',
          lg: '0px',
          md: '0px',
          sm: '5px',
          xs: '5px'
        },
        paddingRight: {
          xl: '0px',
          lg: '0px',
          md: '0px',
          sm: '5px',
          xs: '5px'
        }
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: '30px',
            gap: {
              xl: '22px',
              lg: '22px',
              md: '16px',
              sm: '16px',
              xs: '16px'
            }
          }}
        >
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: {
                xl: '8px',
                lg: '8px',
                md: '3px',
                sm: '3px',
                xs: '3px'
              },
              display: {
                xl: 'flex',
                lg: 'flex',
                md: 'flex',
                sm: 'flex',
                xs: 'flex'
              }
            }}
          >
            <img
              src={image.src}
              alt={image.alt}
              style={{
                width: type === 'ChatGPT' ? '72px' : '90px'
              }}
            ></img>
          </Box>

          <Box>
            <Typography
              sx={{
                color: '#410A85',
                opacity: 1,
                fontFamily: 'Inter',
                fontSize: {
                  xl: '24px',
                  lg: '24px',
                  md: '18px',
                  sm: '18px',
                  xs: '18px'
                },
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '125%',
                marginBottom: '5px'
              }}
            >
              {title}
            </Typography>

            <Typography
              sx={{
                color: '#655679',
                opacity: 1,
                fontFamily: 'Inter',
                fontSize: {
                  xl: '16px',
                  lg: '16px',
                  md: '14px',
                  sm: '14px',
                  xs: '14px'
                },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '150%',
                marginTop: '5px'
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Box>

      {type === 'Clarice.AI' && (
        <Box>
          <ConfettiExplosion
            force={0.6}
            duration={2500}
            particleCount={40}
            width={1000}
          ></ConfettiExplosion>
        </Box>
      )}
    </Box>
  );
}

export default AppChosenSuccess;
