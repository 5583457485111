import React from 'react';
import { Box } from '@mui/material';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[];
};

function AppBody({ children }: RootInterface) {
  return (
    <Box
      sx={{
        width: '100%',
        marginLeft: '30px',
        marginRight: '30px',
        marginTop: {
          xl: '64px',
          lg: '64px',
          md: '64px',
          sm: '30px',
          xs: '30px'
        },

        display: 'inline-flex',
        alignItems: 'flex-start',
        gap: '78px'
      }}
    >
      {children}
    </Box>
  );
}

export default AppBody;
