import { createContext, useState } from 'react';
import {
  GlobalContextInterface,
  GlobalContextInterfaceProvider
} from './interface';
import GlobalContextInitial from './state';
import { TranslateInterface } from '../../interfaces/TranslateInterface';
import useLanguage from '../../hooks/useLanguage';

export const GlobalContext =
  createContext<GlobalContextInterface>(GlobalContextInitial);

export const GlobalContextProvider = (
  props: GlobalContextInterfaceProvider
) => {
  const translation: TranslateInterface = useLanguage();

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const [language, setLanguage] = useState(
    localStorage.getItem('i18nextLng') || 'en'
  );

  const toggleTheme = (theme: any) => {
    setTheme(theme);
  };

  const toggleLanguage = (language: any) => {
    translation.settings.changeLanguage(language);

    localStorage.setItem('i18nextLng', language);

    setLanguage(language);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme,
        language,
        toggleTheme,
        toggleLanguage
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
