import { Modal } from '@mui/material';
import useStyle from './style';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../../utils/contexts/ThemeContext';
import LoadingIcon from '../../../../../../assets/images/loading.svg';

type Props = {
  loading: any;
};

function Loading({ loading }: Props) {
  const classes = useStyle();

  const themeMode = useContext(ThemeContext);

  return (
    <Modal open={!!loading} className={classes.root}>
      <img src={LoadingIcon} alt="Clarice.ai" className={classes.img} />
    </Modal>
  );
}

export default Loading;
