import React, { useContext } from 'react';
import Root from './components/Root';
import Header from './components/Header';
import Body from './components/Body';
import { Grid } from '@mui/material';
import Steps from './components/Steps';
import Step from './components/Step';
import Chat from './components/Chat';
import Introduction from './components/Introduction';
import Loading from './components/Loading';
import Form from './components/Form';
import Prompts from './components/Prompts';
import Prompt from './components/Prompt';
import ChosenSuccess from './components/ChosenSuccess';
import Outputs from './components/Outputs';
import Output from './components/Output';
import { useAppDispatch } from '../../../../utils/hooks/useAppDispatch';
import useForm from './hooks/useForm';

type Props = {};

const ComparisonPageLayout = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    chosen,
    outputs,
    prompts,
    steps,
    step,
    form,
    loading,
    handleChange,
    handleSubmit,
    handlePrompt,
    handleOutput,
    handleReset
  } = useForm(dispatch);

  return (
    <Root>
      <Header handleReset={handleReset} />

      <Body>
        <Grid
          container
          spacing={{
            xl: 2,
            lg: 2,
            md: 1,
            sm: 0,
            xs: 0
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={4.5} xl={4}>
            <Steps>
              {steps.map(
                ({
                  id,
                  title,
                  description
                }: {
                  id: number;
                  title: string;
                  description: string;
                }) => (
                  <Step
                    key={id}
                    active={id === step}
                    title={title}
                    description={description}
                  />
                )
              )}
            </Steps>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7.5} xl={8}>
            <Chat>
              {step === 1 && !loading && <Introduction />}

              {step === 1 && loading && <Loading form={form} />}

              {step === 1 && (
                <Form
                  form={form}
                  loading={loading}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              )}

              {step === 1 && (
                <Prompts>
                  <Grid container spacing={2}>
                    {prompts.map((prompt: string, index: number) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={6}
                        key={index}
                      >
                        <Prompt handlePrompt={handlePrompt} prompt={prompt} />
                      </Grid>
                    ))}
                  </Grid>
                </Prompts>
              )}

              {step === 3 && chosen !== undefined && (
                <ChosenSuccess outputs={outputs} chosen={chosen} />
              )}

              {(step === 2 || step === 3) && outputs.length > 0 && (
                <Outputs>
                  <Grid container spacing={2}>
                    {outputs.map((output: any, index: number) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        key={index}
                      >
                        <Output
                          handleOutput={handleOutput}
                          output={output}
                          version={index + 1}
                          show={step === 3 && chosen !== undefined}
                          chosen={output.id === chosen}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Outputs>
              )}
            </Chat>
          </Grid>
        </Grid>
      </Body>
    </Root>
  );
};

export default ComparisonPageLayout;
