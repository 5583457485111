import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import Button from '../Button';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ThemeContext } from '../../../../../../utils/contexts/ThemeContext';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { GlobalContextInterface } from '../../../../../../utils/contexts/GlobalContext/interface';
import { GlobalContext } from '../../../../../../utils/contexts/GlobalContext';

type Interface = {
  output: any;
  handleOutput: (output: string, language: string) => void;
  version: string | number;
  show?: boolean;
  chosen?: any;
};

function AppOutput({ output, handleOutput, version, show, chosen }: Interface) {
  const themeMode = useContext(ThemeContext);

  const baseURL = `/icons/${themeMode.mode === 'dark' ? 'dark' : 'light'}/`;

  const { translate }: TranslateInterface = useLanguage();

  const ctx: GlobalContextInterface = useContext(GlobalContext);

  return (
    <Box
      sx={
        show
          ? {
              display: 'flex',
              padding: '32px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: ' 10px',
              borderRadius: '12px 12px 12px 0px',
              background: '#FFF',
              border: chosen
                ? '1px solid rgba(124, 36, 251, 0.80)'
                : '1px solid #FFFFFF',
              boxShadow: '0px 16px 40px -8px rgba(33, 37, 41, 0.08)',
              marginBottom: '25px'
            }
          : {
              display: 'flex',
              padding: '32px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: ' 10px',
              borderRadius: '12px 12px 12px 0px',
              background: '#FFF',
              boxShadow: '0px 16px 40px -8px rgba(33, 37, 41, 0.08)',
              border: '1px solid #FFFFFF',
              marginBottom: '25px',
              '&:hover': {
                border: '1px solid rgba(124, 36, 251, 0.80)',
                boxShadow: '0px 16px 40px -8px rgba(33, 37, 41, 0.08)'
              }
            }
      }
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            minHeight: '30px'
          }}
        >
          {show && (
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: output.by === 'Clarice.ai' ? '3px' : '8px'
              }}
            >
              <img
                src={
                  output.by === 'Clarice.ai'
                    ? baseURL + 'clarice-tag.svg'
                    : baseURL + 'gpt-tag.svg'
                }
                alt={output.by === 'Clarice.ai' ? 'Tag Clarice.ai' : 'Tag GPT'}
                style={{ width: '23px' }}
              ></img>
            </Box>
          )}

          <Box>
            <Typography
              sx={{
                color: '#655679',
                opacity: 1,
                fontFamily: 'Inter',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '165%',
                marginBottom: '5px'
              }}
            >
              {show
                ? output.by
                : translate('page.comparison.output.version') + ' ' + version}
            </Typography>
          </Box>
        </Box>

        {output.text.split('\n').map((el: any, index: number) => {
          return (
            <Typography
              key={index}
              sx={{
                color: '#655679',
                opacity: 1,
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '180%',
                marginTop: '5px',
                marginBottom: show ? '15px' : '15px'
              }}
            >
              {el}
            </Typography>
          );
        })}

        {!show && (
          <Button
            onClick={() => handleOutput(output.id, ctx.language)}
            width="192px"
            height="55px"
          >
            {translate('page.comparison.output.buttons.choose')}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default AppOutput;
