import React, { useContext } from 'react';
import useStyle from './style';
import { ThemeContext } from '../../../../../../../../utils/contexts/ThemeContext';
import clsx from 'clsx';
import {
  Accordion,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  useTheme,
  Box
} from '@mui/material';

type Props = {
  corrections?: any;
  activeItem?: any;
};

function Premium({ corrections, activeItem }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const baseURL: string = `/icons/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  const quantity: any = corrections.length || 0;

  if (quantity === 0) return null;

  const result = {};

  corrections.forEach(item => {
    result[`ITEM_${item._id}`] = true;
  });

  const isExpanded = !!corrections.find(
    (deviation: any) => deviation._id === activeItem
  );

  const idQuantities: any = {};

  // Iterando sobre o array de dados para calcular as quantidades
  corrections.forEach((item: any) => {
    const { id } = item;
    if (idQuantities[id]) {
      idQuantities[id].quantity += 1;
    } else {
      idQuantities[id] = {
        id,
        title: item.custom.title,
        quantity: 1
      };
    }
  });

  // Convertendo o objeto em um array
  const response: any = idQuantities ? Object.values(idQuantities) : [];

  const deviationsPRO: any = response.slice(0, 3);

  if (response && response.length > 3) {
    const remainingItems = response.slice(3);

    const totalQuantity = remainingItems.reduce(
      (total, item) => total + item.quantity,
      0
    );

    deviationsPRO.push({
      title: 'Outros desvios',
      quantity: totalQuantity
    });
  }

  const onRedirect = () => {
    window.open('https://app.clarice.ai/plans', '_blank');
  };

  return (
    <Accordion
      elevation={0}
      className={clsx(classes.root, `ITEM_PREMIUM_CTA`, {
        'active-item-card': isExpanded,
        ...result
      })}
      style={{
        boxShadow:
          themeMode.mode === 'light'
            ? '0px 2px 12px -8px rgba(0, 0, 0, 0.16)'
            : '0px 18px 56px -12px rgba(227, 227, 238, 0.123)'
      }}
      expanded={false}
    >
      <AccordionSummary
        style={{
          // width: '22rem',
          cursor: 'auto',
          padding: '16px',
          minHeight: '50px !important',
          // height: '200px',
          alignItems: 'start',
          marginBottom: '10px!important'
        }}
      >
        <Grid
          container
          alignItems={'left'}
          sx={{ margin: '0px', width: '100%' }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ width: '100%' }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: '700',

                fontStyle: 'normal',
                lineHeight: '124%',
                color:
                  themeMode.mode === 'light'
                    ? '#636363'
                    : theme.palette.text.white
              }}
            >
              Desvios PRO
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ width: '100%' }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={'row'}
              width={'100%'}
              sx={{ paddingTop: '16px', paddingBottom: '24px' }}
            >
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontSize: '13.5px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '124%',
                  paddingRight: '16px',
                  color:
                    themeMode.mode === 'light'
                      ? '#636363'
                      : theme.palette.text.white
                }}
              >
                Encontramos mais{' '}
                <strong>
                  {quantity === 1
                    ? `${quantity} desvio de estilo`
                    : `${quantity} desvios de estilo`}{' '}
                </strong>
                no seu texto disponíveis no plano PRO.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ width: '100%' }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={'row'}
              width={'100%'}
              sx={{ paddingTop: '16px', paddingBottom: '24px' }}
            >
              <Box>
                {deviationsPRO.map((item: any, index: number) => {
                  return (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      flexDirection={'row'}
                      width={'100%'}
                      key={index}
                      sx={{
                        marginBottom:
                          deviationsPRO.length - 1 === index ? '0px' : '16px'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          padding: '4px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '8px',
                          borderRadius: '1000px',
                          background: '#FFC560'
                        }}
                      >
                        <Typography
                          style={{
                            textAlign: 'center',
                            width: '15px',
                            height: '15px',
                            fontFamily: 'Inter',
                            fontSize: '11px',
                            fontWeight: '700',
                            fontStyle: 'normal',
                            color:
                              themeMode.mode === 'light'
                                ? '#000'
                                : theme.palette.text.white
                          }}
                        >
                          {item.quantity}
                        </Typography>
                      </div>

                      <Typography
                        style={{
                          marginLeft: '8px',
                          fontFamily: 'Inter',
                          fontSize: '13.5px',
                          fontWeight: '600',
                          fontStyle: 'normal',
                          lineHeight: '140%',
                          paddingRight: '16px',
                          color:
                            themeMode.mode === 'light'
                              ? '#636363'
                              : theme.palette.text.white
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item.title
                        }}
                      />
                    </Box>
                  );
                })}
              </Box>

              <Box
                display={'flex'}
                alignItems={'center'}
                sx={{ minWidth: '110px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    padding: '20px',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '1000px',
                    background: 'rgba(255, 197, 96, 0.32)'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      padding: '8px',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '1000px',
                      background: '#FFC560'
                    }}
                  >
                    <Typography
                      style={{
                        textAlign: 'center',
                        width: '30px',
                        height: '30px',
                        fontFamily: 'Inter',
                        fontSize: '24px',
                        fontWeight: '700',
                        fontStyle: 'normal',
                        lineHeight: '124%',
                        color:
                          themeMode.mode === 'light'
                            ? '#000'
                            : theme.palette.text.white
                      }}
                    >
                      {quantity}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ width: '100%' }}
          >
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'row'}
              width={'100%'}
            >
              <Button
                variant="contained"
                onClick={onRedirect}
                style={{
                  marginTop: '12px',
                  boxShadow: '0px 2px 12px -8px rgba(0, 0, 0, 0.16)',
                  // boxShadow: 'unset',
                  padding: '16px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',

                  gap: '10px',
                  borderRadius: '6px',
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  backgroundColor:
                    themeMode.mode === 'light'
                      ? `#09B286`
                      : `${theme.palette.secondary.main}`,
                  color:
                    themeMode.mode === 'light'
                      ? `#FFF`
                      : `${theme.palette.text.black}`,
                  textAlign: 'center',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '100%'
                }}
                sx={{
                  '& > MuiButton-startIcon': {
                    marginRight: '0px'
                  }
                }}
                startIcon={
                  <img
                    className={classes.iconButton}
                    src={baseURL + 'star.svg'}
                    alt=""
                  />
                }
              >
                Experimente agora
              </Button>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Accordion>
  );
}

export default Premium;
