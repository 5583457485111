import React from 'react';
import useStyle from './style';
import Button from '@mui/material/Button';
import { useAppDispatch } from '../../../../../../../../../utils/hooks/useAppDispatch';
import { ArrowForward } from '@mui/icons-material';

type Props = {
  correction: any;
  modal: {
    score: {
      open?: boolean;
      toggle: () => void;
    };
    summary: {
      open?: boolean;
      toggle: () => void;
    };
    premium: {
      open?: boolean;
      toggle: () => void;
    };
  };
};

const ReplaceWord = (props: Props) => {
  const classes = useStyle();

  const { correction } = props;

  const [loading, setLoading] = React.useState(false);

  if (!correction?.replacement || correction.replacement.length === 0)
    return null;

  const incorrectWord = correction.spans[0].content;

  const correctWord = correction.replacement[0];

  const correctWordLabel =
    correction.id === 'WHITESPACE_RULE'
      ? 'Remover espaço'
      : correctWord === ''
      ? incorrectWord
      : correctWord;

  // const dispatch: any = useAppDispatch();

  const onReplaceWord = async () => {
    props.modal.premium.toggle();

    // setLoading(true);
    //
    // const cb = {
    //   success: () => {
    //     setLoading(false);
    //   },
    //   error: () => {
    //     setLoading(false);
    //   }
    // };

    // dispatch(
    //   sendFeedback(
    //     {
    //       textId: '',
    //       docsId: '',
    //       correctionId: correction._id,
    //       message: '',
    //       type: 'agree',
    //       correctionType: correction.type
    //     },
    //     cb
    //   )
    // );
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          className={classes.box}
          style={{
            alignItems: 'center',
            justifyContent:
              correction.id === 'WHITESPACE_RULE' || correctWord === ''
                ? 'center'
                : 'center' // 'space-between'
          }}
        >
          {correction.id !== 'WHITESPACE_RULE' && correctWord !== '' && (
            <p
              className={classes.incorrectWord}
              style={{
                textDecoration: 'line-through red'
              }}
            >
              {incorrectWord}
            </p>
          )}

          {correction.id !== 'WHITESPACE_RULE' && correctWord === '' && (
            <>
              <Button
                className={classes.buttonIncorrectWord}
                style={
                  correctWord === ''
                    ? {
                        textDecorationLine: 'line-through',
                        textTransform: 'none'
                      }
                    : {}
                }
                onClick={onReplaceWord}
              >
                {correctWordLabel}
              </Button>
            </>
          )}

          {correction.id !== 'WHITESPACE_RULE' && correctWord !== '' && (
            <>
              <div>
                <ArrowForward
                  key={'icon'}
                  fontSize={'small'}
                  className={classes.arrow}
                />
              </div>

              <Button
                className={classes.buttonCorrectWord}
                style={
                  correctWord === ''
                    ? { textDecorationLine: 'line-through' }
                    : {}
                }
                onClick={onReplaceWord}
              >
                {correctWordLabel}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReplaceWord;
