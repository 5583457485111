import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    minHeight: '230px',
    // padding: '30px 0px 82px 0px',
    padding: '0px 0px 30px 0px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 15px 0px'
    }
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: '#fff!important',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(164, 163, 255, 0.3)'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: '60px',
    marginRight: '20px',
    padding: 30,
    height: '80px',
    minHeight: '60px !important'
  },
  space: {
    flexGrow: 1
  }
}));

export default style;
