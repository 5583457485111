export const DarkPalette = {
  primary: {
    main: '#410A85'
  },
  secondary: {
    main: '#0fedb2'
  },
  success: {
    main: '#47BE42'
  },
  error: {
    main: '#FF5989'
  },
  strokes: {
    grey: '#EEEEEE'
  },
  text: {
    primary: '#ffffff',
    // secondary: '#636363',
    secondary: '#959595',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
    lightInfo: '#C5C5C5',
    white: '#fff',
    dark: '#393939',
    black: '#2f2f2f'
  },
  background: {
    default: '#252525',
    paper: '#252525',
    lightWarning: '#F2F2F2',
    lightGrey: '#F8F8F8',
    lighterGrey: 'rgba(255, 255, 255, 0.055)',
    darkGrey: '#E8E8E8'
  },
  common: {
    white: '#ffffff',
    blue: '#4489ff',
    green: '#47BE42',
    purple: '#885abd',
    yellow: '#ffbf49',
    red: '#e55478',
    pink: '#FF5989',
    black: '#000',
    tPurple: '#7c24fa',
    lightPurple: '#7C24FA',
    darkMint: '#46BD9F',
    darkGrey: '#707070',
    disabledPrimary: '#300763',
    tooltip: 'rgba(0,0,0,0.58)'
  },
  highlights: {
    correcao: opacity => `rgba(228, 76, 94, ${opacity})`,
    clareza: opacity => `rgba(68, 137, 255, ${opacity})`,
    concisao: opacity => `rgba(70, 189, 159, ${opacity})`,
    forca: opacity => `rgba(136, 90, 189, ${opacity})`,
    originalidade: opacity => `rgba(255, 191, 73, ${opacity})`,
    formatacao: opacity => `rgba(160, 160, 160, ${opacity})`,
    ortografia: opacity => `rgba(244, 67, 54, ${opacity})`,
    gramatica: opacity => `rgba(255, 153, 0, ${opacity})`,
    pontuacao: opacity => `rgba(177, 122, 255, ${opacity})`,
    duvida: opacity => `rgba(7, 141, 255, ${opacity})`,
    premium: opacity => `rgba(255, 191, 73, ${opacity})`
  }
};
