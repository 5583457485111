import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {},
  box: {
    '&::-webkit-scrollbar': {
      width: '0.4em',
      backgroundColor: '#ffffff'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#ffffff'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#410a85bd'
    }
  }
}));

export default style;
