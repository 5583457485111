import React, { useContext } from 'react';
import useStyle from './style';
import { useTheme } from '@mui/material';
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify,
  FormatListNumbered,
  FormatListBulleted,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  StrikethroughS,
  FormatQuote
} from '@mui/icons-material';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';

type Props = {
  type: string;
};

function Icon({ type }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  if (type === 'left') {
    return <FormatAlignLeft className={classes.customIcon} />;
  }

  if (type === 'center') {
    return <FormatAlignCenter className={classes.customIcon} />;
  }

  if (type === 'right') {
    return <FormatAlignRight className={classes.customIcon} />;
  }

  if (type === 'justify') {
    return <FormatAlignJustify className={classes.customIcon} />;
  }

  if (type === 'unordered') {
    return <FormatListBulleted className={classes.customIcon} />;
  }

  if (type === 'blockquote') {
    return <FormatQuote className={classes.customIcon} />;
  }

  if (type === 'ordered') {
    return <FormatListNumbered className={classes.customIcon} />;
  }

  if (type === 'bold') {
    return <FormatBold className={classes.customIcon} />;
  }

  if (type === 'italic') {
    return <FormatItalic className={classes.customIcon} />;
  }

  if (type === 'underline') {
    return <FormatUnderlined className={classes.customIcon} />;
  }

  if (type === 'strikethrough') {
    return <StrikethroughS className={classes.customIcon} />;
  }

  if (type === 'HEADER_ONE') {
    return <span className={classes.customIconHeader}>H1</span>;
  }

  if (type === 'HEADER_TWO') {
    return <span className={classes.customIconHeader}>H2</span>;
  }

  if (type === 'HEADER_THREE') {
    return <span className={classes.customIconHeader}>H3</span>;
  }

  if (type === 'HEADER_FOUR') {
    return <span className={classes.customIconHeader}>H4</span>;
  }

  return <></>;
}

export default Icon;
