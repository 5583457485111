import { createContext, useState } from 'react';
import {
  EditorContextInterface,
  EditorContextInterfaceProvider
} from './interface';
import EditorContextInitial from './state';

export const EditorContext =
  createContext<EditorContextInterface>(EditorContextInitial);

export const EditorContextProvider = (
  props: EditorContextInterfaceProvider
) => {
  const [activeItem, setActiveItem] = useState(null);

  const [activeCategory, setActiveCategory] = useState('all');

  const onSetActiveCategory = (category: string) => {
    setActiveCategory(category);
  };

  const onSetActiveItem = (item: any) => {
    setActiveItem(item);
  };

  return (
    <EditorContext.Provider
      value={{
        activeItem,
        onSetActiveItem,
        activeCategory,
        onSetActiveCategory
      }}
    >
      {props.children}
    </EditorContext.Provider>
  );
};
