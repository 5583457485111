export const LightPalette = {
  primary: {
    main: '#410A85'
  },
  secondary: {
    main: '#0fedb2'
  },
  success: {
    main: '#47BE42'
  },
  error: {
    main: '#FF5989'
  },
  strokes: {
    grey: '#EEEEEE'
  },
  text: {
    primary: '#4A4A4A',
    secondary: '#636363',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
    lightInfo: '#C5C5C5',
    white: '#fff',
    dark: '#393939',
    black: '#2f2f2f'
  },
  background: {
    default: '#f4f4f4',
    paper: '#ffffff',
    lightWarning: '#F2F2F2',
    lightGrey: '#FCFBFE',
    lighterGrey: '#F2F2F2',
    darkGrey: '#E8E8E8'
  },
  common: {
    white: '#ffffff',
    blue: '#4489ff',
    green: '#47BE42',
    purple: '#885abd',
    yellow: '#ffbf49',
    red: '#e55478',
    pink: '#FF5989',
    black: '#000',
    tPurple: '#7c24fa',
    lightPurple: '#7C24FA',
    darkMint: '#46BD9F',
    darkGrey: '#707070',
    disabledPrimary: '#300763',
    tooltip: '#410A85'
  },
  highlights: {
    correction: opacity => `rgba(228, 76, 94, ${opacity})`,
    clarity: opacity => `rgba(68, 137, 255, ${opacity})`,
    conciseness: opacity => `rgba(70, 189, 159, ${opacity})`,
    strength: opacity => `rgba(136, 90, 189, ${opacity})`,
    originality: opacity => `rgba(26, 189, 200, ${opacity})`,
    premium: opacity => `rgba(255, 191, 73, ${opacity})`
  }
};
