const getWordLimit = (text: any, subscriber = false) => {
  const WORD_LIMIT = {
    FREE: {
      STRING: '1000',
      NUMBER: 1000
    },
    PAID: {
      STRING: '5 mil',
      NUMBER: 5000
    }
  };

  const wordLimit = subscriber
    ? WORD_LIMIT.PAID.NUMBER
    : WORD_LIMIT.FREE.NUMBER;

  const wordCounter = text.trim().split(/\s+/).length;

  const tooltipInfo =
    wordCounter > wordLimit ? (
      !subscriber ? (
        <>
          Seu texto excede {WORD_LIMIT.FREE.STRING} palavras ({wordCounter}).
          Para revisar mais palavras simultâneas,{' '}
          <span
            style={{ color: 'rgb(15, 237, 178)', cursor: 'pointer' }}
            onClick={() => {
              window.open('https://app.clarice.ai/plans', '_blank');
            }}
          >
            {' '}
            assine o PRO
          </span>
          .
        </>
      ) : (
        `Eu só consigo revisar ${WORD_LIMIT.PAID.STRING} palavras simultâneas. Seu texto possui ${wordCounter} palavras.`
      )
    ) : (
      ''
    );

  return {
    limit: wordLimit,
    counter: wordCounter,
    tooltip: tooltipInfo
  };
};

export default getWordLimit;
