import React from 'react';
import useStyle from './style';
import { Box, Typography } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';

type Props = {
  text?: any;
  author?: any;
};

function Citation({ text, author }: Props) {
  const classes = useStyle();

  return (
    <Box className={classes.citation} width={'100%'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <FormatQuote className={classes.icon} />
        <Typography
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent="flex-end"
      >
        <Typography
          className={classes.author}
          dangerouslySetInnerHTML={{ __html: author }}
        />
      </Box>
    </Box>
  );
}

export default Citation;
