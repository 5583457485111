import toast from 'react-hot-toast';
import { state } from './const';

const welcome = async (prefix: string, user: any): Promise<any> => {
  const content = user.name
    ? `${prefix} ${user.name.split(' ')[0]}!`
    : `${prefix}!`;

  const extra: any = {
    icon: '👏',
    style: {
      background: '#000000',
      color: '#fff'
    },
    position: 'bottom-center'
  };

  return toast(content, {
    ...state.options,
    ...extra
  });
};

const goodbye = async (prefix: string, user: any): Promise<any> => {
  const name = user.name.split(' ')[0];

  const content =
    name !== '' ? `${prefix} ${name.split(' ')[0]}!` : `${prefix}!`;

  const extra: any = {
    icon: '👏',
    style: {
      background: '#000000',
      color: '#fff'
    },
    position: 'bottom-center'
  };

  return toast(content, {
    ...state.options,
    ...extra
  });
};

const standard = async (content, autoClose: number = 2000): Promise<any> => {
  const extra: any = {
    icon: '🦄'
  };

  return toast(content, {
    ...state.options,
    ...extra
  });
};

const success = async (content, autoClose: number = 2000): Promise<any> => {
  const extra: any = {};

  return toast.success(content, {
    ...state.options,
    ...extra
  });
};

const error = async (content, autoClose: number = 2000): Promise<any> => {
  const extra: any = {};

  return toast.error(content, {
    ...state.options,
    ...extra
  });
};

const notify: any = {
  welcome,
  goodbye,
  standard,
  success,
  error
};

export default notify;
