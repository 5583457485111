import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import appStateSlice from '../redux/features/appStateSlice';
import loadingReducer from './loading';

const appReducer = combineReducers({
  appState: appStateSlice,
  loading: loadingReducer
});

const RootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

const storeCreator = createStore(
  RootReducer,
  compose(
    applyMiddleware(thunk),
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

export default storeCreator;
