import { Box, CircularProgress, Typography } from '@mui/material';
import useStyle from './style';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../../utils/contexts/ThemeContext';

function AppLoading({ form }: any) {
  const classes = useStyle();

  const themeMode = useContext(ThemeContext);

  return (
    <div className={classes.root}>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'flex-end'
        }}
      >
        <Box
          display="flex"
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: ' 30px',
            marginBottom: '50px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '16px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: ' 10px',
              borderRadius: '12px',
              background: '#FFF',
              boxShadow: '0px 16px 40px -8px rgba(33, 37, 41, 0.08)',
              border: '1px solid #FFFFFF'
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: '#655679',
                  opacity: 1,
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '125%'
                }}
              >
                {form.message}
              </Typography>
            </Box>
          </Box>
        </Box>

        <CircularProgress
          sx={{
            color: theme =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
          }}
          size={80}
        />
      </Box>
    </div>
  );
}

export default AppLoading;
