import { Box } from '@mui/material';
import useStyle from './style';
import { useState } from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { Sidebar } from '../../../index';

type Props = {
  categories: any;
  activeCategory: string;
  onSetActiveCategory: (category: string) => void;
  corrections: any;
  innerHeight: number;
};

function CategoryRoot({
  categories,
  activeCategory,
  onSetActiveCategory,
  corrections,
  innerHeight
}: Props) {
  const classes = useStyle();

  return (
    <Box
      sx={{
        zIndex: 999
      }}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className={classes.root}
    >
      <Box
        pt="12px"
        pb={innerHeight < 750 ? '3px' : '5px'}
        sx={{
          width: '100%',
          padding: '12px 8px 5px'
        }}
      >
        <Button
          className={clsx(classes.allSuggestions, {
            [classes.allSuggestionsActive]: activeCategory === 'all'
          })}
          sx={{
            width: '100%',
            letterSpacing: '0.1px',
            cursor: 'pointer',
            textTransform: 'unset',
            marginBottom: innerHeight < 750 ? '7px' : '10px',
            marginTop: innerHeight < 750 ? '7px' : '10px',
            fontFamily: 'Inter',
            fontSize: '13px',
            fontWeight: '500',
            borderRadius: '8px',
            gap: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            padding: '8px 8px'
          }}
          onClick={() => onSetActiveCategory('all')}
        >
          Todas as sugestões
        </Button>

        {categories.map((category: any, index: number) => (
          <Sidebar.Category.Item
            key={category.name + '_deviations'}
            name={category.name}
            type={category.type}
            number={
              corrections.filter(
                correction => correction.category === category.type
              ).length || category.number
            }
            color={category.type}
            tooltip={category.tooltip}
            active={category.type === activeCategory}
            onSetActiveCategory={() => {
              if (category.type !== activeCategory) {
                onSetActiveCategory(category.type);
              }
            }}
            innerHeight={innerHeight}
          />
        ))}
      </Box>
    </Box>
  );
}

export default CategoryRoot;
