import React, { useContext, useState } from 'react';
import { Box, Grid, TextareaAutosize, useTheme } from '@mui/material';
import useStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Alert from '../Alerts';
import { GlobalContextInterface } from '../../../../../../utils/contexts/GlobalContext/interface';
import { GlobalContext } from '../../../../../../utils/contexts/GlobalContext';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

type CategoryItemProps = {
  textValue: string;
  output: any;
  handleChange: any;
  handleReset: () => void;
};

const TextAreas = ({
  textValue,
  output,
  handleChange,
  handleReset
}: CategoryItemProps) => {
  const classes = useStyle();
  const theme = useTheme();
  const warningLimit = 1000;
  const ctx: GlobalContextInterface = useContext(GlobalContext);
  const { translate }: TranslateInterface = useLanguage();

  const isCharacterLimitReached = textValue.length >= warningLimit;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box className={classes.stylebox} position="relative">
          <FontAwesomeIcon
            icon={faTrashCan}
            className={classes.trashIcon}
            onClick={handleReset}
          />
          <TextareaAutosize
            minRows={15}
            placeholder={translate(
              'page.summarizeText.form.fields.messages.placeholder'
            )}
            value={textValue}
            onChange={handleChange}
            style={{
              width: '100%',
              border: 'none',
              padding: theme.spacing(2),
              borderRadius: '15px',
              fontSize: '15px',
              fontStyle: 'normal',
              fontFamily: 'Inter',
              fontWeight: '400',
              height: '290px',
              overflow: 'auto',
              lineHeight: '1.5'
            }}
          />
          {!isCharacterLimitReached && (
            <div className={classes.characterCounter}>
              {textValue.length}{' '}
              {translate(
                'page.summarizeText.form.alert.messages.characterCounter'
              )}
            </div>
          )}
          <Alert
            message={`${translate(
              'page.summarizeText.form.alert.messages.counter'
            )} : ${textValue.length}/${warningLimit}`}
            show={isCharacterLimitReached}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box className={classes.stylebox}>
          <Box
            style={{
              width: '100%',
              border: 'none',
              height: '290px',
              overflowY: 'auto',
              marginTop: '10px',
              paddingLeft: '12px',
              paddingRight: '12px'
            }}
          >
            {output !== ''
              ? output
              : translate(
                  'page.summarizeText.form.fields.messages.placeholderResult'
                )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TextAreas;
