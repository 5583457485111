import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    border:
      theme.palette.mode === 'light' ? '2px solid #EEE' : `1px solid #404040`,

    borderRadius: '8px',
    boxShadow: '0px 18px 56px -12px rgba(227, 227, 238, 0.8)',
    '&:before': {
      display: 'none'
    },
    margin: '0 !important',
    marginBottom: '18px !important'
    // maxWidth: '400px'
  },
  quantity: {
    background: 'rgb(124, 36, 250)!important',
    borderRadius: '20px',
    padding: '5px 3px',
    minWidth: '17px',
    textAlign: 'center',
    font: 'normal normal 700 10px/7px Inter',
    color: theme.palette.mode === 'light' ? 'white' : theme.palette.text.primary
  },
  iconButton: {
    width: '18px',
    height: '18px'
  }
}));

export default style;
