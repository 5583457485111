import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[] | any;
};

function AppBody({ children }: RootInterface) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: {
          xl: '30px',
          lg: '30px',
          md: '30px',
          sm: '30px',
          xs: '30px'
        },
        display: 'inline-flex',
        alignItems: 'flex-start'
        // gap: '78px'
      }}
      width={'100vw'}
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      flexDirection={isMobile ? 'column' : 'row'}
      // mt={isMobile ? '90px' : '120px'}
      // ml={isMobile ? '0' : '30px'}
      // paddingRight={isMobile ? '0' : '10rem'}
    >
      {children}
    </Box>
  );
}

export default AppBody;
