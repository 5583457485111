const LANGUAGE_PT: any = {
  configurator: {
    language: {
      options: {
        portuguese: 'Português',
        english: 'Inglês'
      }
    }
  },
  page: {
    choice: {
      options: {
        comparison: 'Clarice.ai vs ChatGPT',
        textGenerator: 'Reescrever texto',
        textReviewer: 'Revisar texto',
        summarizeText: 'Resumir texto',
        hashtagGenerator: 'Gerar hashtag'
      }
    },
    comparison: {
      introduction: {
        title: 'ChatGPT vs Clarice.AI',
        description: 'Descubra a diferença e selecione o melhor.'
      },
      steps: {
        one: {
          title: 'Etapa 1',
          description:
            'Coloque o que você deseja escrever em um campo de texto.'
        },
        two: {
          title: 'Etapa 2',
          description: 'Clique no texto que você mais gosta.'
        },
        three: {
          title: 'Etapa 3',
          description:
            'Agora você sabe! Compartilhe o resultado nas redes sociais.'
        }
      },
      prompts: {
        one: {
          description:
            'Estou no WebSummit descobrindo novas startups de tecnologia'
        },
        two: {
          description: 'Estou viajando pela Europa'
        },
        three: {
          description:
            'Escreva uma postagem de blog sobre inteligência artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/coloque aqui sobre o que você quer escrever'
          }
        },
        buttons: {
          submit: 'Gerar texto'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite uma mensagem!'
          },
          default: 'Algo deu errado!'
        }
      },
      output: {
        version: 'Versão',
        buttons: {
          choose: 'Eu escolho este'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'Você escolheu ChatGPT!',
          description: 'Apenas 10% dos usuários escolheram ChatGPT.'
        },
        'Clarice.AI': {
          title: 'Você escolheu a Clarice.AI!',
          description:
            'Você não é o único, mais de 90% dos usuários escolheram a Clarice.ai.'
        }
      }
    },
    rewriter: {
      introduction: {
        title: 'Reescrever texto com IA',
        description:
          'A ferramenta de reescrever textos da Clarice.ai permite reescrever palavras, frases e parágrafos sem alterar o significado original do seu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Digite o seu texto aqui...',
            placeholderResult: 'O seu texto reescrito aparecerá aqui'
          }
        },
        alert: {
          messages: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Reescrever'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    summarizeText: {
      introduction: {
        title: 'Resumir texto com IA',
        description:
          'A ferramenta para resumir textos da Clarice.ai permite resumir palavras, frases e parágrafos sem alterar o significado original do seu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Digite o seu texto aqui...',
            placeholderResult: 'O seu texto reescrito aparecerá aqui'
          }
        },
        alert: {
          messages: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Resumir'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    hashtagGenerator: {
      introduction: {
        title: 'Gerador de hashtags',
        description: 'Insira o texto e obtenha hashtags.'
      },
      steps: {
        one: {
          title: 'Etapa 1',
          description:
            'Coloque o que você deseja escrever em um campo de texto.'
        },
        two: {
          title: 'Etapa 2',
          description: 'Clique no texto que você mais gosta.'
        },
        three: {
          title: 'Etapa 3',
          description:
            'Agora você sabe! Compartilhe o resultado nas redes sociais.'
        }
      },
      prompts: {
        one: {
          description:
            'Estou no WebSummit descobrindo novas startups de tecnologia'
        },
        two: {
          description: 'Estou viajando pela Europa'
        },
        three: {
          description:
            'Escreva uma postagem de blog sobre inteligência artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/coloque aqui o seu texto'
          }
        },
        buttons: {
          submit: 'Gerar hashtags'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite uma mensagem!'
          },
          default: 'Algo deu errado!'
        }
      },
      output: {
        version: 'Versão',
        buttons: {
          choose: 'Eu escolho este'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'Você escolheu ChatGPT!',
          description: 'Apenas 10% dos usuários escolheram ChatGPT.'
        },
        'Clarice.AI': {
          title: 'Você escolheu a Clarice.AI!',
          description:
            'Você não é o único, mais de 90% dos usuários escolheram a Clarice.ai.'
        }
      }
    }
  }
};

export default LANGUAGE_PT;
