import { AppBar, Toolbar } from '@mui/material';
import useStyle from './style';
import Logo from '../Logo';
import Button from '@mui/material/Button';
import Language from '../Language';

function AppHeader({ handleReset }: any) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Logo handleReset={handleReset} />

          <div className={classes.space} />

          <Language />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppHeader;
