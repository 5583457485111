import instance from '../../utils/axios/instance';
import waiting from '../../utils/functions/waiting';
import { convertToRaw } from 'draft-js';

const ReviewerRequest = {
  checkText: async (props?: any) => {
    const response = await instance().post(`/tools/reviewer/check`, {
      ...props,
      editorState: convertToRaw(props.editorState.getCurrentContent())
    });

    return response.data;
  },
  saveText: async (props?: any) => {
    const response = await instance().post(`/tools/reviewer/save`, {
      ...props,
      editorState: convertToRaw(props.editorState.getCurrentContent())
    });

    return response.data;
  },
  saveTitle: async (props?: any) => {
    const response = await instance().post(`/tools/reviewer/title`, {
      ...props
    });

    return response.data;
  },
  getText: async (id?: any) => {
    await waiting(500);

    const response = await instance().get(`/tools/reviewer/${id}`);

    return response.data;
  },
  acceptSuggestion: async (correction?: any) => {
    await waiting(300);

    return {
      summary: 'Texto sumarizado!'
    };
  },
  dismissSuggestion: async (correction?: any) => {
    await waiting(300);

    return {
      summary: 'Texto sumarizado!'
    };
  },
  getSummary: async (text?: string) => {
    await waiting(1000);

    return {
      summary: 'Texto sumarizado!'
    };
  }
};

export default ReviewerRequest;
