import React from 'react';
import useStyle from './style';
import { Drawer } from '@mui/material';

type RootProps = {
  children: React.ReactNode;
};

function Root({ children }: RootProps) {
  const classes = useStyle();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={true}
      PaperProps={{ className: classes.drawerPaper }}
    >
      {children}
    </Drawer>
  );
}

export default Root;
