import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: '#fff!important',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(164, 163, 255, 0.3)'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: '60px',
    marginRight: '20px',
    padding: 30,
    height: '80px',
    minHeight: '60px !important',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '5px',
      marginRight: '5px',
      paddingLeft: '5px',
      paddingRight: '5px'
    }
  },
  space: {
    flexGrow: 1
  }
}));

export default style;
