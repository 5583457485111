import React, { useContext } from 'react';
import useStyle from './style';
import { AccordionDetails, Typography, useTheme } from '@mui/material';
import { Correction } from '../../../index';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';

type Props = {
  correction: any;
  modal: {
    score: {
      open?: boolean;
      toggle: () => void;
    };
    summary: {
      open?: boolean;
      toggle: () => void;
    };
    premium: {
      open?: boolean;
      toggle: () => void;
    };
  };
};

function Detail({ correction, modal }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  return (
    <AccordionDetails
      style={{
        paddingTop: 0,
        paddingLeft: '22px',
        paddingRight: '22px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}
    >
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '13.5px',
          fontWeight: '400',
          color:
            themeMode.mode === 'light'
              ? theme.palette.text.secondary
              : theme.palette.text.lightInfo,
          letterSpacing: '0.12px'
        }}
        dangerouslySetInnerHTML={{ __html: correction.custom.feedback }}
      />

      <Correction.Item.Replace.Word correction={correction} modal={modal} />

      {correction?.custom?.citation && (
        <Correction.Item.Citation
          text={correction?.custom?.citation.text}
          author={correction?.custom?.citation.author}
        />
      )}

      {correction?.custom?.more && (
        <Correction.Item.MoreTips more={correction?.custom?.more} />
      )}
    </AccordionDetails>
  );
}

export default Detail;
