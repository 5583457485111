import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import createStore from './stories/reducers/createStore';
import reportWebVitals from './reportWebVitals';
import './assets/css/general.css';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import Router from './utils/router';
import { ThemeContextProvider } from './utils/contexts/ThemeContext';
import './utils/locales/i18n';
import analytics from './utils/functions/analytics';

analytics.initGA();

const store = createStore;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeContextProvider>
        <ToastContainer />
        <CssBaseline />
        <BrowserRouter basename="/">
          <Router />
        </BrowserRouter>
      </ThemeContextProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
