import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(3px)',
    '& > div,img': {
      outline: 'none'
    }
  },
  img: {
    width: '90px',
    height: '150px'
  }
}));

export default style;
