import { AppBar, Toolbar, Box } from '@mui/material';
import useStyle from './style';
import { useContext } from 'react';
import { ThemeContext } from '../../../../utils/contexts/ThemeContext';
import FullLogo from '../../../../assets/images/jsx-icons/full-logo';

function AppLogo({}: any) {
  const classes = useStyle();

  const themeMode = useContext(ThemeContext);

  return (
    <Box
      position={'absolute'}
      left={{
        xl: '55px',
        lg: '55px',
        md: '55px',
        sm: '20px',
        xs: '20px'
      }}
    >
      <FullLogo
        className={classes.logo}
        color={themeMode.mode === 'light' ? 'primary' : 'primary'}
      />
    </Box>
  );
}

export default AppLogo;
