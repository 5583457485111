import Root from './components/Root';
import CategoryRoot from './components/Category/Root';
import CategoryItem from './components/Category/Item';
import ScoreRoot from './components/Score/Root';
import ScoreHeader from './components/Score/Header';
import ScoreBody from './components/Score/Body';
import ScoreCircular from './components/Score/Circular';
import ScoreAction from './components/Score/Action';
import ModalRoot from './components/Modal/Root';
import ModalScore from './components/Modal/Score';
import ModalSummary from './components/Modal/Summary';
import ModalPremium from './components/Modal/Premium';

export const Sidebar = {
  Root,
  Category: {
    Root: CategoryRoot,
    Item: CategoryItem
  },
  Score: {
    Root: ScoreRoot,
    Header: ScoreHeader,
    Body: ScoreBody,
    Circular: ScoreCircular,
    Action: ScoreAction
  },
  Modal: {
    Root: ModalRoot,
    Score: ModalScore,
    Summary: ModalSummary,
    Premium: ModalPremium
  }
};
