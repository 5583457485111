import {
  ContentState,
  convertToRaw,
  EditorState,
  SelectionState,
  Modifier
} from 'draft-js';

const createCorrectionEntities = (editorState, corrections) => {
  const items: any = corrections || [];

  const contentState = editorState.getCurrentContent();

  let currentOffset = 0;
  const blocks = contentState.getBlocksAsArray();
  const blocksSpan = blocks.map(block => {
    const text = block.getText();
    const span = { start: currentOffset, end: currentOffset + text.length };
    currentOffset = span.end + 1;
    return {
      text,
      span,
      key: block.getKey()
    };
  });

  let contentStateWithItemEntities = contentState;

  items.forEach((item, idx) => {
    if (item.hide || !item.spans[0]) return;
    const block = blocksSpan.find(
      block =>
        block.span.start <= item.spans[0].offset &&
        block.span.end >= item.spans[0].offset
    );
    if (!block) return;

    contentStateWithItemEntities = contentStateWithItemEntities.createEntity(
      'CORRECTION',
      'MUTABLE',
      {
        ...item,
        itemId: `ITEM_${item.id}_${item._id}`
      }
    );

    const entityKey = contentStateWithItemEntities.getLastCreatedEntityKey();

    item.spans.forEach(span => {
      const selection = SelectionState.createEmpty(block.key)
        .set('anchorOffset', span.offset - block.span.start)
        .set('focusOffset', span.offset - block.span.start + span.length);
      try {
        // console.log({ contentStateWithItemEntities, selection, entityKey });

        contentStateWithItemEntities = Modifier.applyEntity(
          contentStateWithItemEntities,
          selection,
          entityKey
        );
      } catch (e) {
        console.error(
          'Error applying entity',
          selection,
          entityKey,
          convertToRaw(contentStateWithItemEntities)
        );
      }
    });
  });

  return EditorState.set(editorState, {
    currentContent: contentStateWithItemEntities
  });
};

export default createCorrectionEntities;
