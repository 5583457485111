import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {
    marginTop: '15px',
    marginBottom: '5px',
    width: '100%'
  },
  container: {
    height: 'auto',
    paddingRight: '15.6px',
    paddingLeft: '15.6px',
    paddingTop: '5.8px',
    paddingBottom: '5.8px',
    marginBottom: '5px'
  },
  box: {
    // height: '35px',
    display: 'flex',
    alignItems: 'center'
  },
  incorrectWord: {
    // color: '#393939',
    // fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#FF5989!important'
  },
  buttonIncorrectWord: {
    // color: '#393939',
    // fontFamily: 'Inter',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    marginTop: '0px',
    marginBottom: '0px',
    color: '#FF5989!important',
    padding: '6px!important',
    background: '#fff5f773 0% 0% no-repeat padding-box',
    border: '1px solid #FF5989!important',
    borderRadius: '7px!important'
  },
  buttonCorrectWord: {
    width: 'auto',
    height: '2.0rem',
    color: '#09B286!important',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '140%',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
    padding: '6px!important',
    border: '1px solid #09B286!important',
    borderRadius: '7px!important',
    '&:hover': {
      backgroundColor: 'rgba(9,178,134,0.85)'
    },
    '&:disabled': {
      opacity: 0.7,
      cursor: 'default'
    }
  },
  arrow: {
    marginLeft: '13px',
    marginRight: '13px',
    marginTop: '3px'
  }
}));

export default style;
