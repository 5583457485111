import { GlobalContextProvider } from '../../../../../../utils/contexts/GlobalContext';
import React from 'react';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[];
};

function AppRoot({ children }: RootInterface) {
  return <GlobalContextProvider>{children}</GlobalContextProvider>;
}

export default AppRoot;
