import React from 'react';
import { Box } from '@mui/material';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[];
};

function AppBody({ children }: RootInterface) {
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '0 15px'
      }}
    >
      {children}
    </Box>
  );
}

export default AppBody;
