// AppChat.js
import React from 'react';
import { Box } from '@mui/material';

type RootInterface = {
  children: React.ReactElement | React.ReactElement[] | any;
};

function TextAreaContainer({ children }: RootInterface) {
  return (
    <Box
      sx={{
        margin: 'auto',
        width: '100%',
        padding: {
          xl: '0px 44px 46px 0px',
          lg: '0px 20px 46px 0px',
          md: '0px 0px 15px 0px',
          sm: '0px 0px 15px 0px',
          xs: '0px 0px 15px 0px'
        },
        flexDirection: 'column',
        gap: {
          xl: '48px',
          lg: '48px',
          md: '32px',
          sm: '24px',
          xs: '24px'
        }
      }}
    >
      {children}
    </Box>
  );
}

export default TextAreaContainer;
