import { LightPalette } from './LightPalette';
import { DarkPalette } from './DarkPalette';
import { createTheme } from '@mui/material';

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    color: {
      textWhite: any;
    };
  }
}

declare module '@mui/material/styles/createPalette' {
  interface ThemeOptions {
    overrides: any;
  }

  interface TypeText {
    lightInfo?: string;
    white?: string;
    dark?: string;
    black?: string;
    hint?: string;
  }

  interface TypeBackground {
    lightWarning?: string;
    lightGrey?: string;
    lighterGrey: string;
    darkGrey?: string;
  }

  interface CommonColors {
    blue?: string;
    green?: string;
    purple?: string;
    yellow?: string;
    red?: string;
    pink?: string;
    tPurple?: string;
    lightPurple?: string;
    darkMint?: string;
    darkGrey?: string;
    disabledPrimary?: string;
    tooltip?: string;
  }
  interface Palette {
    highlights: {
      correction?: any;
      clarity?: any;
      conciseness?: any;
      strength?: any;
      originality?: any;
      premium?: any;
    };
  }
}

const getThemeByMode = (mode: 'light' | 'dark') =>
  createTheme({
    palette: {
      mode,
      ...(mode === 'light' ? LightPalette : DarkPalette)
    },
    typography: {
      htmlFontSize: 16,

      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '6rem',
        lineHeight: 1.167,
        letterSpacing: '-0.01562em'
      },
      h2: {
        fontFamily: 'Open Sans',
        fontWeight: 300,
        fontSize: '3.75rem',
        lineHeight: 1.2,
        letterSpacing: '-0.00833em'
      },
      h3: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '3rem',
        lineHeight: 1.167,
        letterSpacing: '0em'
      },
      h4: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '2.125rem',
        lineHeight: 1.235,
        letterSpacing: '0.00735em'
      },
      h5: {
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: 1.334,
        letterSpacing: '0em'
      },
      h6: {
        fontFamily: 'Open Sans',
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: 1.6,
        letterSpacing: '0.0075em'
      },
      color: {
        textWhite: '#fff'
      }
    }
    // overrides: {
    //   MuiBackdrop: {
    //     root: {
    //       backdropFilter: 'blur(2.5px)'
    //     }
    //   }
    // }
  });

export default getThemeByMode;
