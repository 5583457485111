import CorrectionStrategy from './Correction/function/CorrectionStrategy';
import CorrectionComponent from './Correction/components/CorrectionComponent';

const decorators = (activeItem, activeCategory) => {
  return {
    strategy: CorrectionStrategy,
    component: (props: any) => {
      return (
        <CorrectionComponent
          {...props}
          activeItem={activeItem}
          activeCategory={activeCategory}
        />
      );
    }
  };
};

export default decorators;
