import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  drawer: {},
  drawerPaper: {
    marginTop: '81px',
    width: 200,
    backgroundColor:
      theme.palette.mode === 'light'
        ? `#ffffff!important`
        : `${theme.palette.background.default}!important`
  }
}));

export default style;
