import Root from './components/Root';
import ItemRoot from './components/Item/Root';
import ItemSummary from './components/Item/Summary';
import ItemDetail from './components/Item/Detail';
import ItemActions from './components/Item/Actions';
import Citation from './components/Item/Citation';
import MoreTips from './components/Item/MoreTips';
import ReplaceWord from './components/Item/ReplaceWord';
import Close from './components/Item/Close';
import Premium from './components/Premium';

export const Correction = {
  Root,
  Premium,
  Item: {
    Root: ItemRoot,
    Summary: ItemSummary,
    Detail: ItemDetail,
    Actions: ItemActions,
    Citation,
    MoreTips,
    Replace: {
      Word: ReplaceWord
    },
    Close: Close
  }
};
