import React, { useContext } from 'react';
import useStyle from './style';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';
import { FileCopyOutlined } from '@mui/icons-material';

type Props = {
  onCopyToClipboard: () => void;
};

function Copy({ onCopyToClipboard }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

  return (
    <Button
      startIcon={<FileCopyOutlined fontSize={'small'} />}
      size={'small'}
      color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
      style={{
        textTransform: 'unset',
        font: 'normal normal 400 13.5px/15px Inter',
        letterSpacing: '0.12px',
        padding: '5px 12px',
        margin: '0 3px !important',
        borderRadius: '7px',
        position: 'fixed',
        bottom: '16px',
        right: '220px',
        zIndex: 1001
      }}
      onClick={onCopyToClipboard}
    >
      Copiar Texto
    </Button>
  );
}

export default Copy;
