import React, { useContext } from 'react';
import useStyle from './style';
import { Accordion, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';

type Props = {
  correction?: any;
  expanded: boolean;
  children: React.ReactNode | any;
};

function Root({ correction, expanded, children }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  return (
    <Accordion
      elevation={0}
      className={clsx(classes.root, `ITEM_${correction._id}`, {
        'active-item-card': expanded
      })}
      sx={{
        boxShadow:
          themeMode.mode === 'light'
            ? '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
            : '0px 18px 56px -12px rgba(227, 227, 238, 0.123)',
        '&:before': { height: '0px' },
        border: '1px solid #E8E8E8'
      }}
      expanded={expanded}
    >
      {children}
    </Accordion>
  );
}

export default Root;
