import React, { useContext } from 'react';
import useStyle from './style';
import { AccordionActions, Box, Button, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';

type Props = {
  correction: any;
  onAccept: (correction: any) => void;
  onDismiss: (correction: any) => void;
  modal: {
    score: {
      open?: boolean;
      toggle: () => void;
    };
    summary: {
      open?: boolean;
      toggle: () => void;
    };
    premium: {
      open?: boolean;
      toggle: () => void;
    };
  };
};

function Actions({ correction, onAccept, onDismiss, modal }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isViewCorrectButton =
    correction.replacement && correction?.replacement[0] !== '';

  const isViewCorrectionAutomatic = [
    'USO_PALAVRAS_REPETIDAS',
    'SUBSTANTIVOS_RASTEJANTES',
    'USO_FRASE_CLICHE',
    'GLUE_WORDS',
    'ADVERBIOS_MENTE',
    'FRASE_LONGA',
    'VAGUEZA',
    'USO_CADA_VEZ_MAIS',
    'USO_PLEONASMO',
    'USO_FACE_VAR',
    'USO_AO_PASSO_QUE',
    'USO_POSTO_QUE',
    'USO_COMO_SENDO',
    'USO_ISSO_PORQUE',
    'USO_PALAVRAS_SENSIVEIS',
    'USO_PARA_PODER',
    'USO_GROSSO_MODO',
    'USO_UM_DOS_MAIS',
    'USO_FRENTE_VAR',
    'USO_ALEM_VAR',
    'USO_AFIM_VAR',
    'USO_PREZAR_VAR',
    'USO_MEDIANTE_VAR',
    'DOIS_SLOTS',
    'USO_EXPRESSAO_PROLIXA'
  ].includes(correction.id);

  return (
    <AccordionActions
      style={{
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent:
          !isViewCorrectButton && !isViewCorrectionAutomatic
            ? 'flex-end'
            : 'space-between',
        flexDirection: 'row',
        paddingBottom: '20px',
        paddingLeft: '15px',
        paddingRight: '15px'
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
        width={'100%'}
      >
        <Box>
          {isViewCorrectButton && !isViewCorrectionAutomatic && (
            <Button
              size={'small'}
              variant={'outlined'}
              className={classes.buttonAccept}
              style={{
                textTransform: 'unset',
                fontFamily: 'Inter',
                fontSize: '13.5px',
                fontWeight: '500',
                letterSpacing: '0.12px',
                padding: '5px 12px',
                margin: '0 3px !important',
                borderRadius: '7px',
                color: theme.palette.success.main,
                borderColor: theme.palette.success.main,
                background: '#0FEDB208 0% 0% no-repeat padding-box'
              }}
              onClick={() => onAccept(correction)}
            >
              Corrigido
            </Button>
          )}

          {isViewCorrectionAutomatic && (
            <Button
              size={'small'}
              variant={'outlined'}
              className={classes.buttonAccept}
              style={{
                textTransform: 'unset',
                fontFamily: 'Inter',
                fontSize: '13.5px',
                fontWeight: '500',
                letterSpacing: '0.12px',
                padding: '5px 12px',
                margin: '0 3px !important',
                borderRadius: '7px',
                borderColor:
                  themeMode.mode === 'light'
                    ? `#7C24FB`
                    : `${theme.palette.secondary.main}`,
                color:
                  themeMode.mode === 'light'
                    ? `#7C24FB`
                    : `${theme.palette.secondary.main}`
              }}
              onClick={() => {
                modal.premium.toggle();
              }}
            >
              Gerar sugestões
            </Button>
          )}
        </Box>
        <Box>
          <Button
            size={'small'}
            variant={'outlined'}
            className={classes.buttonDismiss}
            onClick={() => onDismiss(correction)}
            style={{
              fontFamily: 'Inter',
              textTransform: 'unset',
              fontSize: '13.5px',
              fontWeight: '500',
              letterSpacing: '0.12px',
              padding: '5px 12px',
              margin: '0 3px !important',
              borderRadius: '7px',
              color: theme.palette.error.main,
              borderColor: theme.palette.error.main,
              background:
                '0% 0% no-repeat padding-box padding-box rgba(255, 89, 137, 0.03)'
            }}
          >
            Dispensar
          </Button>
        </Box>
      </Box>
    </AccordionActions>
  );
}

export default Actions;
