import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useAlertStyle = makeStyles((theme: Theme) => ({
  root: {
    color: '#ff0000c4',
    position: 'absolute',
    bottom: 8,
    left: '8px',
    backgroundColor: 'white',
    padding: '5px 11px',
    borderRadius: '6px',
    border: '1px solid',
    borderColor: '#ff0000c4',
    fontSize: '0.875rem',
    width: 'auto',
    zIndex: 2
  }
}));

export default useAlertStyle;
