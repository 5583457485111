const LANGUAGE_EN: any = {
  configurator: {
    language: {
      options: {
        portuguese: 'Portuguese',
        english: 'English'
      }
    }
  },
  page: {
    choice: {
      options: {
        comparison: 'Clarice.ai vs ChatGPT',
        textGenerator: 'Generate text',
        textReviewer: 'Review text',
        summarizeText: 'Summarize text',
        hashtagGenerator: 'Generate hashtag'
      }
    },
    comparison: {
      introduction: {
        title: 'ChatGPT vs Clarice.AI',
        description: 'Discover the difference and select the best.'
      },
      steps: {
        one: {
          title: 'Step 1',
          description: 'Put what you want to write about in a text field.'
        },
        two: {
          title: 'Step 2',
          description: 'Click at the text that you like the most.'
        },
        three: {
          title: 'Step 3',
          description: 'Now you know! Share the result in social media.'
        }
      },
      prompts: {
        one: {
          description: 'I am in WebSummit discovering new tech startups'
        },
        two: {
          description: "I'm traveling around Europe"
        },
        three: {
          description: 'Write a blog post about artificial intelligence'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/put here what you want to write about'
          }
        },
        buttons: {
          submit: 'Generate the text'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter a message!'
          },
          default: 'Something went wrong!'
        }
      },
      output: {
        version: 'Version',
        buttons: {
          choose: 'I choose this'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'You have chosen ChatGPT!',
          description: 'Only 10% of users chose ChatGPT.'
        },
        'Clarice.AI': {
          title: 'You have chosen Clarice.AI!',
          description:
            'You’re not the only one, more than 90% of the users have chosen Clarice.'
        }
      }
    },
    rewriter: {
      introduction: {
        title: 'Rewrite text with AI',
        description:
          "Clarice.ai's text rewriting tool allows you to rewrite words, sentences and paragraphs without changing the original meaning of your text."
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Enter your text here...',
            placeholderResult: 'Your rewritten text will appear here'
          }
        },
        alert: {
          messages: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Rewrite'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    summarizeText: {
      introduction: {
        title: 'RSummarize text with AI',
        description:
          "Clarice.ai's text summarizing tool allows you to summarize words, sentences and paragraphs without changing the original meaning of your text."
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Enter your text here...',
            placeholderResult: 'Your rewritten text will appear here'
          }
        },
        alert: {
          messages: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Summarize'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    hashtagGenerator: {
      introduction: {
        title: 'Hashtag generator',
        description: 'Enter text and get hashtags'
      },
      steps: {
        one: {
          title: 'Step 1',
          description: 'Put what you want to write about in a text field.'
        },
        two: {
          title: 'Step 2',
          description: 'Click at the text that you like the most.'
        },
        three: {
          title: 'Step 3',
          description: 'Now you know! Share the result in social media.'
        }
      },
      prompts: {
        one: {
          description: 'I am in WebSummit discovering new tech startups'
        },
        two: {
          description: "I'm traveling around Europe"
        },
        three: {
          description: 'Write a blog post about artificial intelligence'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/put your text here'
          }
        },
        buttons: {
          submit: 'Generate the hashtags'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter a message!'
          },
          default: 'Something went wrong!'
        }
      },
      output: {
        version: 'Version',
        buttons: {
          choose: 'I choose this'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'You have chosen ChatGPT!',
          description: 'Only 10% of users chose ChatGPT.'
        },
        'Clarice.AI': {
          title: 'You have chosen Clarice.AI!',
          description:
            'You’re not the only one, more than 90% of the users have chosen Clarice.'
        }
      }
    }
  }
};

export default LANGUAGE_EN;
