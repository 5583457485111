import React, { useContext, useMemo, useState } from 'react';
import useStyle from './style';
import { useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../../utils/contexts/ThemeContext';
import clsx from 'clsx';
import { getSettings } from './functions/getSettings';
import { DraftailEditor } from 'draftail';
import decorators from './decorators';
import './style/styles.css';
import { EditorState, CompositeDecorator, convertToRaw } from 'draft-js';
import { throttle, debounce } from 'underscore';
import SuggestionStrategy from './decorators/Suggestion/function/SuggestionStrategy';
import SuggestionComponent from './decorators/Suggestion/components/SuggestionComponent';

type Props = {
  id: string;
  title: string;
  text: any;
  setText: any;
  editorState: any;
  setEditorState: any;
  onCheck: any;
  onSave: any;
  activeCategory: string;
  activeItem: any;
};

function Text({
  id,
  title,
  text,
  setText,
  editorState,
  setEditorState,
  onCheck,
  onSave,
  activeCategory,
  activeItem
}: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const settings = useMemo(() => getSettings(), []);

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
    }
  };

  const onChange = newEditorState => {
    const state = newEditorState;

    const newText = newEditorState.getCurrentContent().getPlainText();

    const newDocument = JSON.stringify(
      convertToRaw(newEditorState.getCurrentContent())
    );

    const oldDocument = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    // CHECK TEXT
    if (text !== newText && newDocument !== oldDocument) {
      onCheck({
        id,
        title,
        text: newText,
        editorState: newEditorState,
        activeCategory,
        activeItem
      });
    }

    // SAVE ONLY EDITOR
    if (text === newText && newText !== '' && newDocument !== oldDocument) {
      onSave({
        id,
        title,
        text: newText,
        editorState: newEditorState,
        activeCategory,
        activeItem
      });
    }

    throttledSetState(state, newText);
  };

  const throttledSetState = React.useCallback(
    throttle((state, newText) => {
      setEditorState(state);

      setText(newText);
    }, 200),
    []
  );

  return (
    <div
      className={clsx(
        classes.root,
        themeMode.mode === 'dark' ? 'darkTheme' : '',
        isMobile ? 'isMobile' : '',
        isMobile && themeMode.mode === 'dark' ? 'isMobileDarkTheme' : ''
      )}
    >
      <DraftailEditor
        editorState={editorState || null}
        onChange={onChange}
        id={settings.id}
        ariaDescribedBy={settings.ariaDescribedBy}
        placeholder={settings.placeholder}
        enableHorizontalRule={isMobile ? false : settings.enableHorizontalRule}
        spellCheck={settings.spellCheck}
        stripPastedStyles={settings.stripPastedStyles}
        entityTypes={settings.entityTypes}
        blockTypes={isMobile ? settings.blockTypesMobile : settings.blockTypes}
        inlineStyles={settings.inlineStyles}
        enableLineBreak={settings.enableLineBreak}
        decorators={[
          decorators(activeItem, activeCategory),
          { strategy: SuggestionStrategy, component: SuggestionComponent }
        ]}
        handleKeyDown={handleKeyDown}
      />
    </div>
  );
}

export default Text;
