import useStyle from './style';
import { useContext, useEffect, useState } from 'react';
import { Sidebar } from '../../../index';
import { useAppDispatch } from '../../../../../../../../../utils/hooks/useAppDispatch';
import {
  useMediaQuery,
  Box,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';
import { getSummary } from '../../../../../../../../../stories/actions/reviewer';

type Props = {
  text?: string;
  summary?: string;
  open: boolean;
  toggle: () => void;
};

function ModalSummary({ text, summary, open, toggle }: Props) {
  const classes = useStyle();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  useEffect(() => {
    if (loading) {
      const cb = {
        success: () => {
          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(getSummary(text, cb));
    }
  }, [loading]);

  useEffect(() => {
    if (open) {
      setLoading(true);
    } else {
      setLoading(true);
    }
  }, [open]);

  return (
    <Sidebar.Modal.Root
      open={open}
      toggle={() => {
        toggle();
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          style={{
            width: '100%'
          }}
        >
          {loading && (
            <Skeleton
              variant="text"
              width="100%!important"
              height={100}
              style={{
                width: '100%',
                minWidth: isMobile ? '280px' : '497px',
                paddingTop: '15px',
                lineHeight: isMobile ? '16px' : '23.68px'
              }}
            />
          )}

          {!loading && (
            <Typography
              style={{
                // textAlign: 'justify',
                color:
                  themeMode.mode === 'dark'
                    ? theme.palette.text.primary
                    : '#39274B',
                fontSize: isMobile ? '12px' : '14px',
                // fontStyle: 'italic',
                paddingTop: '15px',
                lineHeight: isMobile ? '16px' : '23.68px'
              }}
            >
              {summary && summary !== ''
                ? summary
                : 'Por favor, forneça um texto para que seja possível realizar o processo de sumarização.'}
            </Typography>
          )}
        </Box>
      </Box>
    </Sidebar.Modal.Root>
  );
}

export default ModalSummary;
