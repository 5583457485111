import React from 'react';
import useStyle from './style';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '../../../../../../../components/Tooltip';

type Props = {
  title: string;
  onClose: () => void;
};

function Close({ title, onClose }: Props) {
  const classes = useStyle();

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Tooltip title={title} small={true}>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
      </Tooltip>
    </Box>
  );
}

export default Close;
