import React, { useContext } from 'react';
import { MotionInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../../../../../utils/contexts/ThemeContext';
import useStyles from './style';

const Logo = ({}: MotionInterface) => {
  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const baseURL = `/icons/${themeMode.mode === 'dark' ? 'dark' : 'light'}/`;

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        width: '100%',
        // marginTop: isMobile ? '24px' : '32px',
        height: isMobile ? 'auto' : '78px',
        marginBottom: '30px',
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src={baseURL + 'emoji.svg'}
        alt="Emoji Clarice"
        style={{ width: '96px' }}
      ></img>
    </Box>
  );
};

export default Logo;
