import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {},
  scoreText: {
    color:
      theme.palette.mode === 'light' ? '#410A85' : theme.palette.text.white,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: '700',
    letterSpacing: '0.13px',
    lineHeight: '100%'
  }
}));

export default style;
