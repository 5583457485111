import React, { useContext } from 'react';
import useStyle from './style';
import { AccordionSummary, Box, Typography, useTheme } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { Correction } from '../../../index';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';

type Props = {
  correction: any;
  expanded: boolean;
  handleExpand: (_id: any) => void;
};

function Summary({ correction, expanded, handleExpand }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  return (
    <AccordionSummary
      style={{
        width: '25rem',
        maxWidth: '25rem',
        padding: '0 18px',
        minHeight: '50px',
        // height: '50px',
        marginBottom: '10px!important'
      }}
      onClick={() => {
        if (!expanded) {
          handleExpand(correction._id);
        }
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
        width={'100%'}
      >
        <Box display={'flex'}>
          <FiberManualRecord
            style={{
              fontSize: '12px',
              padding: 0,
              margin: 0,
              marginRight: '18px',
              marginTop: '5px',
              color: theme.palette.highlights[correction.category](1)
            }}
          />
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '500',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.text.dark
                  : theme.palette.text.white
            }}
            dangerouslySetInnerHTML={{
              __html: correction.custom.title
            }}
          />
        </Box>

        {!expanded && (
          <Correction.Item.Close title="Dispensar" onClose={() => {}} />
        )}
      </Box>
    </AccordionSummary>
  );
}

export default Summary;
