import Root from './components/Root';
import Header from './components/Header';
import Body from './components/Body';
import { useAppDispatch } from '../../../../utils/hooks/useAppDispatch';
import { Sidebar } from './components/Sidebar';
import useReviewer from './hooks/useReviewer';
import { Container } from './components/Container';
import { Editor } from './components/Editor';
import { Correction } from './components/Correction';
import Loading from './components/Loading';
import React, { useMemo } from 'react';
import getOverlaps from '../../../../utils/functions/getOverlaps';

type Props = {};

const ReviewerPageLayout = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    id,
    text,
    setText,
    editorState,
    setEditorState,
    title,
    onSetTitle,
    onSaveTitle,
    summary,
    score,
    corrections,
    premium,
    loading,
    loaded,
    innerHeight,
    modal,
    isMobile,
    categories,
    activeCategory,
    onSetActiveCategory,
    activeItem,
    onSetActiveItem,
    onCheck,
    onSave,
    onProcess,
    onAccept,
    onDismiss,
    onCopyToClipboard
  }: any = useReviewer(dispatch);

  const filtered = useMemo(() => {
    const newCorrections = corrections.filter((item: any) => {
      if (activeCategory === 'all') {
        return true;
      }

      return item.category === activeCategory;
    });

    return getOverlaps(newCorrections, []).filter(item => !item.hide);
  }, [corrections, activeCategory]);

  return (
    <Root>
      <Header>
        <Editor.Button.Submit
          id={id}
          title={title}
          text={text}
          editorState={editorState}
          activeCategory={activeCategory}
          activeItem={activeItem}
          onProcess={onProcess}
          loading={loading}
        />
      </Header>

      <Body>
        <Container.Root>
          <Editor.Root loading={loading}>
            <Editor.Title
              id={id}
              title={title}
              onSetTitle={onSetTitle}
              onSaveTitle={onSaveTitle}
            />

            <Editor.Text
              id={id}
              title={title}
              text={text}
              setText={setText}
              editorState={editorState}
              setEditorState={setEditorState}
              onCheck={onCheck}
              onSave={onSave}
              activeCategory={activeCategory}
              activeItem={activeItem}
            />

            {!isMobile && <Editor.Metrics.Root text={text} />}

            {!isMobile && (
              <Editor.Button.Copy onCopyToClipboard={onCopyToClipboard} />
            )}
          </Editor.Root>

          {!isMobile && (
            <Correction.Root loading={loading}>
              <Editor.Button.Submit
                id={id}
                title={title}
                text={text}
                editorState={editorState}
                activeCategory={activeCategory}
                activeItem={activeItem}
                onProcess={onProcess}
                loading={loading}
              />

              {(activeCategory === 'all' || activeCategory === 'premium') && (
                <Correction.Premium
                  corrections={premium}
                  activeItem={activeItem}
                />
              )}

              {filtered.map((item: any) => {
                return (
                  <Correction.Item.Root
                    key={item._id}
                    correction={item}
                    expanded={item._id === activeItem}
                  >
                    <Correction.Item.Summary
                      correction={item}
                      expanded={item._id === activeItem}
                      handleExpand={onSetActiveItem}
                    />

                    <Correction.Item.Detail correction={item} modal={modal} />

                    <Correction.Item.Actions
                      correction={item}
                      onAccept={onAccept}
                      onDismiss={onDismiss}
                      modal={modal}
                    />
                  </Correction.Item.Root>
                );
              })}
            </Correction.Root>
          )}

          <Loading loading={!loaded && id} />
        </Container.Root>

        {!isMobile && (
          <Sidebar.Root>
            <Sidebar.Score.Root innerHeight={innerHeight}>
              <Sidebar.Score.Header innerHeight={innerHeight} />

              <Sidebar.Score.Body innerHeight={innerHeight}>
                <Sidebar.Score.Circular
                  size={innerHeight < 750 ? 50 : 70}
                  score={score.text || 0}
                  optionalClick={() => {
                    // modal.score.toggle();
                    modal.premium.toggle();
                  }}
                />
              </Sidebar.Score.Body>

              <Sidebar.Score.Action modal={modal} />
            </Sidebar.Score.Root>

            <Sidebar.Category.Root
              categories={categories}
              activeCategory={activeCategory}
              onSetActiveCategory={onSetActiveCategory}
              corrections={[...corrections, ...premium]}
              innerHeight={innerHeight}
            />

            <Sidebar.Modal.Score
              open={modal.score.open}
              toggle={modal.score.toggle}
            />

            <Sidebar.Modal.Summary
              text={text}
              summary={summary}
              open={modal.summary.open}
              toggle={modal.summary.toggle}
            />

            <Sidebar.Modal.Premium
              open={modal.premium.open}
              toggle={modal.premium.toggle}
            />
          </Sidebar.Root>
        )}
      </Body>
    </Root>
  );
};

export default ReviewerPageLayout;
