import React, { useContext, useEffect, useState } from 'react';
import notify from '../../../../../../utils/notify';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { useMediaQuery } from '@mui/material';
import { hashtagGenerator } from '../../../../../../stories/actions/generative';

const useForm = (dispatch: any) => {
  const { translate }: TranslateInterface = useLanguage();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

  const [form, setForm] = useState<any>({ message: '' });

  const [loading, setLoading] = React.useState(false);

  const [step, setStep] = useState(1);

  const [output, setOutput] = useState<any>('');

  const [chosen, setChosen] = useState<any>(undefined);

  const [modalOpen, setModalOpen] = useState(false);

  const steps = [
    {
      id: 1,
      title: translate('page.hashtagGenerator.steps.one.title'),
      description: translate('page.hashtagGenerator.steps.one.description')
    },
    {
      id: 2,
      title: translate('page.hashtagGenerator.steps.two.title'),
      description: translate('page.hashtagGenerator.steps.two.description')
    },
    {
      id: 3,
      title: translate('page.hashtagGenerator.steps.three.title'),
      description: translate('page.hashtagGenerator.steps.three.description')
    }
  ];

  const prompts = [
    translate('page.hashtagGenerator.prompts.one.description'),
    // translate('page.hashtagGenerator.prompts.two.description'),
    translate('page.hashtagGenerator.prompts.three.description')
  ];

  const handleSubmit = async (e: any, language: string) => {
    if (e) e.preventDefault();

    if (loading) {
      return;
    }

    if (form.message === '') {
      notify.error(translate('page.hashtagGenerator.notify.error.empty.field'));

      return;
    }

    setLoading(true);

    const cb = {
      success: (newOutput: any) => {
        setLoading(false);

        setStep(step + 1);

        // setForm({ ...form, message: '' });

        setOutput(newOutput);

        if (isMobile) {
          window.scrollTo(0, 0);
        }
      },
      error: (type?: string) => {
        if (type === 'LIMIT_REACHED') {
          setModalOpen(true);
        }

        if (type === 'TIME_REACHED') {
          setModalOpen(true);
        }

        setLoading(false);
      }
    };

    setTimeout(() => {
      dispatch(hashtagGenerator(form.message, language, cb));
    }, 0);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const handlePrompt = (prompt: string) => {
    setForm({ ...form, message: prompt });
  };

  const handleReset = () => {
    setLoading(false);

    setForm({ ...form, message: '' });

    setOutput('');

    setChosen(undefined);

    setStep(1);
  };

  return {
    chosen,
    output,
    prompts,
    steps,
    step,
    form,
    setForm,
    loading,
    handleSubmit,
    handleChange,
    handlePrompt,
    handleReset,
    modal: {
      open: modalOpen,
      toggle: () => setModalOpen(!modalOpen)
    }
  };
};

export default useForm;
