import React from 'react';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

type Interface = {
  children: React.ReactElement | React.ReactElement[] | string;
  loading?: boolean;
  onClick: any;
  width?: string;
  height?: string;
};

function AppButton({
  children,
  loading = false,
  onClick,
  width = '30%',
  height = '60px'
}: Interface) {
  return (
    <LoadingButton
      loading={loading}
      disabled={loading}
      variant="contained"
      onClick={onClick}
      sx={{
        color: loading ? `transparent` : '#FFF',
        textTransform: 'none',
        display: 'flex',
        width,
        height: height,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '45px',
        background: 'linear-gradient(59deg, #7C24FB 21.86%, #D19DFB 92.03%)',
        boxShadow: '0px 21px 27px -10px rgba(96, 60, 255, 0.48)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '16px',
        '&:disabled': {
          backgroundColor: 'rgba(124, 36, 251, 0.50)'
        }
      }}
    >
      <FontAwesomeIcon
        icon={faPencil}
        style={{ marginRight: '8px' }}
        size="1x"
      />
      {children}
    </LoadingButton>
  );
}

export default AppButton;
