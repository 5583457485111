import React from 'react';
import useStyle from './style';
import { Box, useMediaQuery } from '@mui/material';

type Props = {
  loading?: boolean;
  children: React.ReactNode;
};

function Root({ loading, children }: Props) {
  const classes = useStyle();

  const isMobile: boolean = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Box
      flex={'0 0 460px'}
      pl={'80px'}
      mr={'90px'}
      pt={'0px'}
      id={'item-card-container'}
      sx={
        isMobile
          ? {}
          : {
              minWidth: '480px'
            }
      }
    >
      {children}
    </Box>
  );
}

export default Root;
