import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  closeIcon: {
    width: '0.9em!important',
    height: '0.9em!important',
    marginLeft: '5px',
    color: '#b1b0b0',
    fontSize: '20px!important',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      color:
        theme.palette.mode === 'light'
          ? `#737070`
          : `${theme.palette.secondary.main}`
    }
  }
}));

export default style;
