import React, { useContext, useState } from 'react';
import useStyle from './style';
import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ThemeContext } from '../../../../../../../../../utils/contexts/ThemeContext';

type Props = {
  more?: any;
};

function MoreTips({ more }: Props) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const [openTips, setOpenTips] = useState(false);

  return (
    <Box width={'100%'}>
      <Box
        mt="10px"
        display={'flex'}
        alignItems={'center'}
        gap={'0.5rem'}
        onClick={() => {
          setOpenTips(!openTips);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: '13.5px',
            letterSpacing: '0.12px',
            color:
              themeMode.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.secondary.main
          }}
        >
          Ver mais dicas
        </Typography>
        {openTips ? (
          <KeyboardArrowUp
            color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
          ></KeyboardArrowUp>
        ) : (
          <KeyboardArrowDown
            color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
          ></KeyboardArrowDown>
        )}
      </Box>

      <Collapse
        in={openTips}
        sx={{
          padding: '1rem!important',
          paddingTop: '0.8rem!important',
          paddingBottom: '0.2rem!important',
          letterSpacing: '0.20px!important'
        }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: '13.5px',
            letterSpacing: '0.20px!important',
            color: theme.palette.text.primary
          }}
          dangerouslySetInnerHTML={{ __html: more }}
        />
      </Collapse>
    </Box>
  );
}

export default MoreTips;
