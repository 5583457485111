import { Box, Grid, useTheme } from '@mui/material';
import useStyle from './style';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../../utils/contexts/ThemeContext';
import { LoadingButton } from '@mui/lab';
import Input from '@mui/material/Input';
import Button from '../Button';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { GlobalContextInterface } from '../../../../../../utils/contexts/GlobalContext/interface';
import { GlobalContext } from '../../../../../../utils/contexts/GlobalContext';

type Interface = {
  form: any;
  loading: boolean;
  handleChange: any;
  handleSubmit: any;
};

function AppForm({ form, loading, handleChange, handleSubmit }: Interface) {
  const classes = useStyle();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx: GlobalContextInterface = useContext(GlobalContext);

  const { translate }: TranslateInterface = useLanguage();

  return (
    <form
      onSubmit={(e: any) => handleSubmit(e, ctx.language)}
      noValidate
      autoComplete="off"
      style={{ width: '100%' }}
    >
      <div className={classes.container}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
            <Box
              component="div"
              sx={{
                '& > :not(style)': { width: '100%' }
              }}
            >
              <Input
                className={classes.input}
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                id="outlined-basic"
                placeholder={translate(
                  'page.hashtagGenerator.form.fields.message.placeholder'
                )}
                value={loading ? '' : form.message}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleChange(e, 'message')
                }
                size="small"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();

                    return handleSubmit(e, ctx.language);
                  }
                }}
                disableUnderline={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div>
              <Button
                loading={loading}
                onClick={(e: any) => handleSubmit(e, ctx.language)}
              >
                {translate('page.hashtagGenerator.form.buttons.submit')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

export default AppForm;
