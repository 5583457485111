import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  root: {},
  allSuggestions: {
    backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#09B286',
    color:
      theme.palette.mode === 'light' ? '#251831!important' : 'white!important',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? 'rgba(65, 10, 133, 0.10)!important'
          : '#09B286!important'
    }
  },
  allSuggestionsActive: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(65, 10, 133, 0.10)!important'
        : '#09B286!important'
  }
}));

export default style;
