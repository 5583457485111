import React, { useContext } from 'react';
import useStyles from './style';
import { useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ThemeContext } from '../../../../../../../../../../../../utils/contexts/ThemeContext';
import { EditorContextInterface } from '../../../../../../../../../../../../utils/contexts/EditorContext/interface';
import { EditorContext } from '../../../../../../../../../../../../utils/contexts/EditorContext';
import { doScrolling } from '../../../../functions/doScrolling';

const CorrectionComponent = ({
  contentState,
  entityKey,
  offsetkey,
  children
}: any) => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles(themeMode);

  const ctx: EditorContextInterface = useContext(EditorContext);

  const item = contentState.getEntity(entityKey).getData();

  const isActive = ctx.activeItem === item._id;

  const style: any = {
    borderRadius: '2px',
    background: 'inherit'
  };

  const category = item?.category || 'correction';

  if (isActive) {
    style.background = theme.palette.highlights[category](0.3);
  } else {
    style.boxShadow = `${theme.palette.highlights[category](
      0.8
    )} 0px -2px 0px inset`;
    style.borderRadius = '0px';
  }

  const onSetActiveCorrectionItem = item => {
    ctx.onSetActiveItem(item._id);

    const cardPremium = document.querySelector(`.ITEM_PREMIUM_CTA`);

    const hoverDOM = document.querySelector(`.TEXT_${item._id}`);
    const cardDOM = document.querySelector(`.ITEM_${item._id}`);
    const activeCardDOM = document.querySelector('.active-item-card');
    const containerDOM = document.querySelector('#item-card-container');
    if (cardDOM && hoverDOM) {
      const bodyY = document.body.getBoundingClientRect().y;
      const cardY = cardDOM.getBoundingClientRect().y - bodyY;
      const hoverY = hoverDOM.getBoundingClientRect().y - bodyY;
      let newMargin = hoverY - cardY - 50;
      // @ts-ignore
      if (containerDOM.style.marginTop !== '') {
        // @ts-ignore
        newMargin += parseInt(containerDOM.style.marginTop);
      }

      if (cardPremium && item.category === 'premium') {
        newMargin = 0;
      }

      if (activeCardDOM) {
        if (activeCardDOM.getBoundingClientRect().y < cardY + bodyY) {
          newMargin +=
            activeCardDOM.getBoundingClientRect().height -
            cardDOM.getBoundingClientRect().height;
        }
      }
      // @ts-ignore
      containerDOM.style.marginTop = newMargin + 'px';
      doScrolling(hoverY - window.innerHeight / 2, 250);
    }

    // if (isMobile) {
    //   ctx.modal.corrections.toggle();
    // }
  };

  return (
    <span
      data-offset-key={offsetkey}
      className={clsx(classes.root, 'TEXT_' + item._id)}
      style={style}
      onClick={() => !isActive && onSetActiveCorrectionItem(item)}
    >
      {children}
    </span>
  );
};

export default CorrectionComponent;
