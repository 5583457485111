import ReactGA from 'react-ga';

const shouldUseAnalytics = async (): Promise<any> => {
  const pathname = window.location.href;

  return pathname.includes('gen.clarice.ai') || pathname.includes('localhost');
};

const initGA = async (): Promise<any> => {
  if (!shouldUseAnalytics()) return;

  ReactGA.initialize('UA-170729772-1');
};

const logPageView = async (): Promise<any> => {
  if (!shouldUseAnalytics()) return;

  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);

  const { fbq }: any = window;

  if (fbq) {
    fbq('track', 'PageView');
  }
};

const logEvent = async (category = '', action = ''): Promise<any> => {
  if (!shouldUseAnalytics()) return;

  if (category && action) {
    ReactGA.event({ category, action });
  }
};

const analytics: any = {
  shouldUseAnalytics,
  initGA,
  logPageView,
  logEvent
};

export default analytics;
