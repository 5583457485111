import { useTranslation } from 'react-i18next';

const useLanguage = () => {
  const { i18n, t } = useTranslation();

  return {
    settings: i18n,
    translate: t
  };
};

export default useLanguage;
