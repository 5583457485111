import React from 'react';
import { Box } from '@mui/material';
import useAlertStyle from './style'; // Import the styles

type AlertProps = {
  message: string;
  show: boolean;
};

const Alert: React.FC<AlertProps> = ({ message, show }) => {
  const classes = useAlertStyle();

  if (!show) return null;

  return <Box className={classes.root}>{message}</Box>;
};

export default Alert;
