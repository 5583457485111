import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  box: {
    width: '100%',
    borderBottom:
      theme.palette.mode === 'light'
        ? '1px solid #e4e6f2'
        : '1px solid #6a6b6e',
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: theme.palette.mode === 'light' ? 'white' : '#303030'
  },
  lastBox: {
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px'
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: '12px',
    textAlign: 'left',
    color: 'theme.palette.text.primary',
    marginRight: '5px!important'
  },
  total: {
    textAlign: 'left',
    color: theme.palette.mode === 'light' ? '#410a85bd' : '#09B286!important',
    font: 'normal normal 700 12px/26px Inter',
    lineHeight: '22px'
  },
  tag: {
    cursor: 'pointer',
    position: 'absolute',
    marginTop: '-50px',
    right: '8px',
    background: '#09B286',
    borderRadius: '6px',
    lineHeight: '26px',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    justifyItems: 'center',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#fff !important',
    font: 'normal normal 700 12px/15px Inter'
  }
}));

export default style;
