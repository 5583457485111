import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { GlobalContextProvider } from '../../utils/contexts/GlobalContext';

const AppLayout = () => {
  return (
    <GlobalContextProvider>
      <Box sx={{ display: 'flex' }}>
        <Outlet />
      </Box>
    </GlobalContextProvider>
  );
};

export default AppLayout;
