import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  buttonAccept: {
    '&:hover': {
      backgroundColor: 'white',
      borderColor: theme.palette.success.main
    }
  },
  buttonDismiss: {
    '&:hover': {
      backgroundColor: 'white',
      borderColor: theme.palette.error.main
    }
  }
}));

export default style;
