import React from 'react';
import useStyle from './style';
import { Box, useMediaQuery } from '@mui/material';

type RootProps = {
  children: React.ReactNode;
};

function Root({ children }: RootProps) {
  const classes = useStyle();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <Box
      width={'100vw'}
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      flexDirection={isMobile ? 'column' : 'row'}
      mt={isMobile ? '60px' : '120px'}
      ml={isMobile ? '0px' : '30px'}
      paddingRight={isMobile ? '0' : '10rem'}
    >
      {children}
    </Box>
  );
}

export default Root;
